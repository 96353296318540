import React, { useContext } from 'react';
import {  
  Button, Card, CardContent, Checkbox, Grid, CircularProgress, Popover, Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import "@progress/kendo-theme-material/dist/all.css";
import { Grid as KendoGrid, GridColumn as Column, GridRowProps } from "@progress/kendo-react-grid";
import { IAuthorizationTab } from '../../../app/models/authorizationTab';
import { AuthorizationType, IAuthorization } from '../../../app/models/authorization';
import AddIcon from '@material-ui/icons/Add';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IWorkspace } from '../../../app/models/workspace';
import { Notifications } from '../../notifications/Notifications';
import { observer } from 'mobx-react-lite';
import MetaDataEditor from '../../meta-data/MetaDataEditor';
import AssetsDialog from '../../assets/AssetsDialog';
import AuthorizationForm from '../form/AuthorizationForm';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { AuthColumnBuilder } from './authColumnBuilder';
import ConfirmDialog from '../../notifications/ConfirmDialog';
import MessageDialog from '../../notifications/MessageDialog';
import { FieldType } from '../../../app/models/field';

interface IProps {
  workspace: IWorkspace | null,
  tab: IAuthorizationTab | undefined,
}

const gridStyles = makeStyles((theme: Theme) => ({
  grid: {
    height: "62vh"
  },
  emptyTab: {
    height: "62vh",
    paddingTop: "15%"
  },
  row: {
    fontSize: "10px",
    '& td': {
      padding: "0px 3px",
      lineHeight: "normal"
    },
    
  },
  headers: {
    fontSize: "12px",
    '& th': {
      padding: "3px 3px"
    }
  },
  dirtyRow: {
    fontWeight: "bold",
  },
  selectedRow: {
    backgroundColor: "rgba(0, 0, 0, 0.07)"
  },
  derivedRow: {
    '& td': {
      color: "#9E9E9E"
    }
  },
  smallSelectBox: {
    padding: "2px 9px"
  },
  divisorColumn: {
    backgroundColor: "rgb(102, 110, 104)",
    color: "#ffffff",
    "text-align": "center !important"
  }
}));

const popoverStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

const AuthorizationTab: React.FC<IProps> = ({
  workspace,
  tab,
}) => {
  
  const rootStore = useContext(RootStoreContext);

  const {
    authorizationsRegistry,
    loadAuthorizations,
    updateAuthorize,
    saveAuthorizations,
    loadingAuthorizations,
    authorize,
    explicitAsset,
    recordsSelected,
    selectRecord,
    selectAllRecords,
    deleteAuthorizations,
    openEditDialog,
    isOpenedEditDialog,
    isOpenedAssetsDialog,
    updateExplicitAsset,
    openExplicitAssets,
    loadTabFulfillmentStatuses,
    isSelectable,
    isInPreviewAuthMode,
    setIsInPreviewAuthMode,
    previewAuthorizations,
    cancelPreviewAuthorizations,
    checkPreviewAuthMode
  } = rootStore.authorizationStore
  
  const classes = gridStyles();
  const popoverClasses = popoverStyles();

  const tabAuthorizations = authorizationsRegistry.get(tab?.id as number);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [openEditErrorDialog, setOpenEditErrorDialog] = React.useState(false);
  const [openDeleteErrorDialog, setOpenDeleteErrorDialog] = React.useState(false);

  const [popover, setPopover] = React.useState<{
    anchorEl: HTMLElement | null,
    text: string | null
  } | null>(null);

  const notifications = new Notifications();

  React.useEffect(() => {    
    if (workspace?.uniqueId && (!tabAuthorizations || !tabAuthorizations.isLoaded)) {
      loadAuthorizations(workspace?.uniqueId as string, tab?.id as number);
    }     
  }, [tabAuthorizations?.authorizations, loadAuthorizations, workspace])

  React.useEffect(() => {
    if (authorize) {
      if ((tabAuthorizations?.changedAuthorizations ?? new Map()).size > 0) {
        const auths = [...tabAuthorizations?.changedAuthorizations.values() ?? []];
        notifications.progress('Saving Authorizations...');
        saveAuthorizations(
          workspace?.uniqueId as string, 
          tab?.id as number
        )
        .then(() => {
          const createdAuths = auths.filter(x => x.isNew).length;
          const updatedAuths = auths.filter(x => x.isUpdated && !x.isNew).length;
          const deletedAuths = auths.filter(x => x.isDeleted).length;
          notifications.success(
            (<div style={{ display: "flex", flexDirection: "column" }}>
              {createdAuths > 0 ? <span style={{ flex: "0 1 25px" }}>{`${createdAuths} new authorization records have been created`}</span> : ""}
              {updatedAuths > 0 ? <span style={{ flex: "0 1 25px" }}>{`${updatedAuths} authorization records have been updated.`}</span> : ""}
              {deletedAuths > 0 ? <span style={{ flex: "0 1 25px" }}>{`${deletedAuths} authorization deleted.`}</span> : ""}
            </div>),
            { time: 5000, width: 450 }
          );
          loadTabFulfillmentStatuses(workspace?.uniqueId as string);
          // notifications.kill();
        }).catch(error => notifications.kill());
        return
      } 

      notifications.info('No Authorization changes detected');
      updateAuthorize(false);
    }
  }, [authorize])

  React.useEffect(() => {
    if (explicitAsset) {      
      if (tab && workspace && workspace.group)
        if (recordsSelected.get(tab.id)?.records.size === 0)
          notifications.info("No Records selected");
        else
          openExplicitAssets(tab.id, workspace.group);
        
      updateExplicitAsset(false);
    }
  }, [explicitAsset, updateExplicitAsset, openExplicitAssets])


  /**
   * Performs the preview authorizations whenever the dashboard is in preview mode
   */
  React.useEffect(() => {
    if(isInPreviewAuthMode){
      previewAuthorizations(workspace?.uniqueId as string, tab?.id as number);
      return;
    }
    
    cancelPreviewAuthorizations(tab?.id as number);
  }, [isInPreviewAuthMode, checkPreviewAuthMode]);

  
  const handleAllCheckboxOnClick = () => {
    selectAllRecords(tab?.id as number);
  }  
  
  const handleCheckboxOnClick = (uniqueId: string) => {
    selectRecord(tab?.id as number, uniqueId);
  }

  const handleDeleteOnClick = () => {
    if (recordsSelected.get(tab?.id as number)?.records.size === 0) {
      return;
    } else {
      if ([...tabAuthorizations?.authorizations.values() ?? []].findIndex(x => 
        (x.type === AuthorizationType.NonExisting || x.type === AuthorizationType.ExplicitWithoutDeliverable) && recordsSelected.get(tab?.id as number)?.records.has(x.uniqueId))> -1) {
          setOpenDeleteErrorDialog(true);
      } else {
        setOpenConfirmDelete(true);
      }
    }
  }

  const handleAddOnClick = () => {
    setOpenDialog(true);
  }

  const handleEditOnClick = () => {    
    if (recordsSelected.get(tab?.id as number)?.records.size === 0) {
      //nothing selected
      return;
    } else {
      if ([...tabAuthorizations?.authorizations.values() ?? []].findIndex(x => 
          (x.type === AuthorizationType.NonExisting || x.type === AuthorizationType.ExplicitWithDeliverable) && recordsSelected.get(tab?.id as number)?.records.has(x.uniqueId))> -1) {
            setOpenEditErrorDialog(true);
        } else {
          openEditDialog(true);
        }
    }
  }
  
  const handleOnRefresh = () => {
    if (authorizationsRegistry.get(tab?.id as number)?.changedAuthorizations.size as number > 0) {
      setOpenConfirm(true);
      return;
    }
    handleConfirm();
  }

  const handleConfirm = (isDelete?: boolean) => {
    if (isDelete) {
      setOpenConfirmDelete(false);
      deleteAuthorizations(tab?.id as number);
      return;
    }    
    setOpenConfirm(false);    
    loadAuthorizations(workspace?.uniqueId as string, tab?.id as number);
    setIsInPreviewAuthMode(false);
    loadTabFulfillmentStatuses(workspace?.uniqueId as string, tab?.id as number);
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setPopover({ 
      anchorEl: event.currentTarget, 
      text: event.currentTarget.getAttribute("data-text") as string 
    });
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  /**
   * Handles the preview authorization click event
   * @returns {void}
   */
  const handlePreviewAuthorizationClick = () => {
    setPopover(null);
    setIsInPreviewAuthMode(true);
  };

  /**
   * Handles the cancel preview authorization click event
   * @returns {void}
   */
  const handleCancelPreviewAuthorizationClick = () => {
    setPopover(null);
    setIsInPreviewAuthMode(false);
  }

  if (!workspace?.uniqueId) {
    return (<React.Fragment>
      <Grid container spacing={3} className={classes.emptyTab} justify="center">
        <Grid item>
         
        </Grid>
      </Grid>
    </React.Fragment>)
  }

  const buildRecordCheckBox = (props: any) => {
    if (!isSelectable(tab?.id as number, props.dataItem as IAuthorization)) {
      return(
        <td>
        </td>
      );
    }

    return(
      <td>
        <Checkbox
          color="primary"
          className={classes.smallSelectBox}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          checked={ recordsSelected.get(tab?.id as number)?.records.get(props.dataItem[props.field ?? ""]) }
          onClick={() => handleCheckboxOnClick(props.dataItem[props.field ?? ""])}
        />
      </td>
    )
  }

  const handleRowRender = (row: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const uniqueId = props.dataItem["uniqueId"];
    const changeAuths = authorizationsRegistry.get(tab?.id as number)?.changedAuthorizations;
    const dirtyRecord = changeAuths?.get(props.dataItem["uniqueId"]);
    const isSelected = recordsSelected.get(tab?.id as number)?.records.has(uniqueId);
    const isDerived = props.dataItem["type"] === AuthorizationType.MetadataMediaId || props.dataItem["type"] === AuthorizationType.ExplicitWithDeliverable || props.dataItem["type"] === AuthorizationType.ExplicitWithoutDeliverable;

    return (
      <tr 
        className={`${classes.row} ${dirtyRecord ? classes.dirtyRow : ''} ${isSelected ? classes.selectedRow : ""} ${isDerived ? classes.derivedRow : ""}`}>
        {row.props.children}
      </tr>
    );
  }

  const buildTabContent = () => {
    var newFields = tab?.fields.filter(x => {
      if(x.type.id !== FieldType.Preset)
        return x;
    });

    if (loadingAuthorizations) {
      return (
        <React.Fragment>
          <Grid container spacing={3} className={classes.emptyTab} justify="center">
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <KendoGrid className={classes.grid + " " + classes.headers}
        data={[...(tabAuthorizations?.authorizations ?? []).values()]}
        scrollable="scrollable"
        rowRender={handleRowRender}
      >
        <Column field="uniqueId" title=" " width={50} cell={(props) => buildRecordCheckBox(props)} headerCell={props => 
        (
          <a className="k-link" onClick={props.onClick}>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              checked={ recordsSelected.get(tab?.id as number)?.allSelected }
              onClick={handleAllCheckboxOnClick}
            />
            {props.children}
          </a>
        )} 
        />
        {newFields?.map((x,i) => {
          return new AuthColumnBuilder(x).build(classes);
        })}
      </KendoGrid>
    )
  }

  const buildMetadataDialog = () => {
    if (openDialog) {
      return(
        <MetaDataEditor 
          tabId={tab?.id as number}
          selected={[...recordsSelected.get(tab?.id as number)?.records.keys() ?? []]} 
          open={openDialog} 
          setOpen={setOpenDialog}
        />
      )
    } 
  }

  const buildEditDialog = () => {
    if (isOpenedEditDialog) {
      return(
        <AuthorizationForm tabId={tab?.id as number} />
      )
    } 
  }

  const buildAssetsDialog = () => {
    if (isOpenedAssetsDialog) {
      return(
        <AssetsDialog />
      )
    } 
  }
  
  const buttonDisabled = authorize || loadingAuthorizations || recordsSelected.get(tab?.id as number)?.records.size === 0;

  return (
    <React.Fragment>
      {buildMetadataDialog()}
      {buildEditDialog()}
      {buildAssetsDialog()}
      <ConfirmDialog 
        title="Refresh Authorizations" 
        body="There are pending changes, are you sure do you want to refresh the grid?"
        open={openConfirm}
        onConfirm={() => handleConfirm()}
        onClose={() => setOpenConfirm(false)}
      />
      <ConfirmDialog 
        title="Delete Authorization Records" 
        body={`Are you sure do you want to delete ${recordsSelected.get(tab?.id as number)?.records.size} records?`}
        open={openConfirmDelete}
        onConfirm={() => handleConfirm(true)}
        onClose={() => setOpenConfirmDelete(false)}
      />
      <MessageDialog 
        title="Unable to Edit Dates" 
        body={`Please unselect explicit rights or blank rows - you cannot make changes to these types of rows.`}
        open={openEditErrorDialog}        
        onClose={() => setOpenEditErrorDialog(false)}
      />
      <MessageDialog 
        title="Unable to Delete Records" 
        body={`Please unselect explicit rights or blank rows - you cannot delete these types of rows.`}
        open={openDeleteErrorDialog}        
        onClose={() => setOpenDeleteErrorDialog(false)}
      />
      <Card variant="outlined">
        <CardContent>
          <Grid container justify="flex-end">
            <Grid>
              {
                isInPreviewAuthMode === false &&
                <Button data-text="Preview Authorizations" size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} 
                  disabled={authorize || loadingAuthorizations}                
                  onClick={handlePreviewAuthorizationClick}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <VisibilityIcon color="primary" />
                </Button>
              }
              {                
                isInPreviewAuthMode === true &&
                <Button data-text="Cancel Preview Authorizations" size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} 
                  disabled={authorize || loadingAuthorizations}
                  onClick={handleCancelPreviewAuthorizationClick}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <VisibilityOffIcon color="primary" />
                </Button>
              }
              &nbsp; &nbsp;
              <Button data-text="Refresh Authorizations" size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} 
                disabled={authorize || loadingAuthorizations}
                onClick={handleOnRefresh}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <RefreshIcon color="primary" />
              </Button>
              &nbsp; &nbsp;
              <Button data-text="Delete Records" size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} 
                disabled={buttonDisabled}
                onClick={handleDeleteOnClick}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <DeleteOutlineIcon color="primary" />
              </Button>
              &nbsp; &nbsp;
              <Button data-text="Edit Records" size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} 
              disabled={buttonDisabled}
              onClick={handleEditOnClick}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              >
                <CalendarToday color="primary"/>
              </Button>
              &nbsp; &nbsp;
              <Button data-text="Add Records" size="small" variant="outlined" color="primary" 
              disabled={buttonDisabled}
              onClick={handleAddOnClick}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              >
                <AddIcon color="primary"/>
                Add Record(s)
              </Button>
              <Popover
                id="mouse-over-popover"
                className={popoverClasses.popover}
                classes={{
                  paper: popoverClasses.paper,
                }}
                open={Boolean(popover)}
                anchorEl={popover ? popover.anchorEl : null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>{popover?.text}</Typography>
              </Popover>
            </Grid>
          </Grid>
          <br />

          {buildTabContent()}

        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default observer(AuthorizationTab);