import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import agent from "../../app/api/agent";
import { LoadingIndicator } from "../../app/layout/LoadingIndicator";

const Home: React.FC = () => {
  const history = useHistory();
  
  useEffect(() => {
    agent.Auth.validate().then(() => {
      history.push("/preauth");
    }).catch((err: AxiosResponse) => {
      console.log(err);      
    });
  }, []);

  return <LoadingIndicator />; 
};

export default Home;
