import { IAudioAuthorization } from './audioAuthorization';

export class AudioAuthorizationRegistry {
  audios: IAudioAuthorization[];

  constructor(audios: IAudioAuthorization[]) {
    this.audios = audios;
  }

  displayAudioTextValue = () => {
    let audiosDisplay = "";
    if (this.audios.length > 0) {
      this.audios.forEach((x, i) => {
        let localValue = "";
        if (x.format)
          localValue += x.format;
  
        if (x.usage)
          localValue += (localValue ? " " : "") + x.usage;

        if (x.config === 'OPT')
          localValue += (localValue ? " " : "") + `(${x.config})`;

        if (x.language)
          localValue += (localValue ? " " : "") + `(${x.language})`;
  
        if (i < this.audios.length - 1)
           localValue += " | ";

        audiosDisplay += localValue;
      });
    }

    return audiosDisplay;
  }
}