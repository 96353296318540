import { Button, Card
  ,CardContent
  ,Dialog
  ,DialogContent
  ,DialogContentText
  ,DialogTitle
  ,Grid
  ,Typography
  ,CircularProgress 
  ,Collapse
  ,makeStyles
  ,Theme
} from '@material-ui/core';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { IFieldGroup, FieldGroup } from '../../app/models/fieldGroup';
import { IField, Field } from '../../app/models/field';
import MetaDataGroup from './MetaDataGroup'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Notifications } from '../notifications/Notifications';
import SoftValidationPopoverAlert from './SoftValidationAlertPopover';


interface IProps {
  tabId: number;
  selected: string[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const MetadataEditor: React.FC<IProps> = ({
  tabId,
  selected,
  open,
  setOpen
}) => {

  const rootStore = useContext(RootStoreContext);
  const notifications = new Notifications();
  const [expanded, setExpanded] = React.useState({
    secondary: false,
    additional: false,
  });
  
  const {
    tabs,
    metadataFieldGroups,
    addNewMetadata
  } = rootStore.authorizationStore

  const {
    selectedFieldValues,
    clearFieldValues,
    isLoadingLookups,
    loadLookupValues,
    lookupValuesRegistry,
    validaterequiredFields,
    softValidateMetadataFields
  } = rootStore.metadatastore

  const classes = useStyles();

  React.useEffect(() => {    
    if (lookupValuesRegistry.size === 0) {
      loadLookupValues();
    }
  }, [lookupValuesRegistry, loadLookupValues])

  const tab = tabs.get(tabId);
  let fieldGroups: Map<number, IFieldGroup> = new Map();
  let groupFields: Map<number, IField[]> = new Map();
  let totalFields: Field[] = [];

  metadataFieldGroups.forEach(x => {
    const fields = tab?.fields.filter(f => f.priority === x.id).map(value => {
      return new Field(value.id as number, value.name as string, value.type.id, value.isRequired, value.binding);
    }) || [];

    if (fields.length > 0) {
      fieldGroups.set(x.id, new FieldGroup(x.id, x.name));
      groupFields.set(x.id, fields);
      totalFields = [...totalFields, ...fields];
    }
  })

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>, result?: boolean) => {
    if (result) {
      if (validaterequiredFields(tabId, totalFields)) {
        if (softValidateMetadataFields(tabId, e?.currentTarget as HTMLButtonElement)) {
          addNewMetadata(tab?.id as number, [...selectedFieldValues.values()]);
          notifications.success('Metadata added succesfully!');
          closeAndCleanDialog()    
        } 
      } else {
        notifications.error('Some required fields are missing!');
        return;
      }
    } else {
      // notifications.info('No changes made!');
      closeAndCleanDialog()
    }
  };

  const closeAndCleanDialog = () => {
    setOpen(false);
    clearFieldValues();
  }

  const handleExpandClick = (groupTypeName: string) => {
    setExpanded({
      ...expanded,
      [groupTypeName]: !expanded[groupTypeName]
    });
  };

  const buildExpandContent = (key: number, groupTypeName: string) => {
    if (expanded[groupTypeName] !== undefined) {
      return (
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded[groupTypeName],
          })}
          onClick={() => handleExpandClick(groupTypeName)}
          aria-expanded={expanded[groupTypeName]}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      )
    }

    return null;
  }

  const buildGroupContent = (key: number, groupTypeName: string) => {

    if (expanded[groupTypeName] !== undefined) {
      return (
        <Collapse in={expanded[groupTypeName]} timeout="auto" unmountOnExit>
          <MetaDataGroup tabId={tabId} group={fieldGroups.get(key)} fields={groupFields.get(key)} />
        </Collapse>
      );
    }

    return <MetaDataGroup tabId={tabId} group={fieldGroups.get(key)} fields={groupFields.get(key)} />
  }

  const buildDialogContent = () => {
    if (isLoadingLookups) {
      return(
        <React.Fragment>
          <Grid container spacing={3} style={{ height: '600px', paddingTop: "15%" }} justify="center">
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </React.Fragment>
      )
    } else {
      return(
        [...fieldGroups.keys()].map((key) => {
          const groupName = fieldGroups.get(key)?.name;
          const groupTypeName = fieldGroups.get(key)?.name?.split(" ")[0].toLowerCase() as string;

          return (
            <Card key={`card-${key}`} variant="outlined" style={{ marginBottom: 10 }}>
              <CardContent style={{ padding: 40, paddingTop: 20 }}>
                <Typography variant="h5" component="h2" style={{ marginBottom: 20 }}>
                  {groupName}
                  {buildExpandContent(key, groupTypeName)}
                </Typography>
                {buildGroupContent(key, groupTypeName)}
              </CardContent>
            </Card>
          )
        })
      )
    }
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={{ marginBottom: -20 }}>
        Add/Edit Asset Metadata
      </DialogTitle>
      <Grid container justify="space-between" style={{ padding: "5px 24px 15px", marginBottom: 0 }}>
        <Grid item>
          <DialogContentText>
            Select metadata for {tab?.name} <br />
            <small>{`${selectedFieldValues.size} of ${tabs.get(tabId)?.fields.length} selected`}</small>
          </DialogContentText>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => handleClose()}>
            Cancel
          </Button>
          &nbsp; &nbsp;
          <Button variant="outlined" color="primary" 
            disabled={ selectedFieldValues.size === 0 } 
            onClick={(e) => handleClose(e, true)}>
            Save
          </Button>
          <SoftValidationPopoverAlert tabId={tabId} setOpen={setOpen}/>
        </Grid>
      </Grid>
      <DialogContent style={{ padding: "25px" }}>
        {buildDialogContent()}
      </DialogContent>
    </Dialog>
  );
}

export default observer(MetadataEditor);