import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  InputAdornment, 
  TextField 
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SearchOutlined } from '@material-ui/icons';
import { IBaseTitle } from '../../app/models/basetitle';
import { FilterOptionsState } from '@material-ui/lab';

interface IProps {
  hierarchyId: number,
  searchTitles: (keyword: string, hierarchyId: number) => Promise<void>,
  titleSuggestions: IBaseTitle[],
  searchTitleVersionsByWprId: (wprids: string[], keyword: string, hierarchyId: number) => Promise<void>,
  isLoadingTitles: boolean
}

const filterOptions = (options: IBaseTitle[], state: FilterOptionsState<IBaseTitle>) => options;

const initialSelected: IBaseTitle = {
    id: 0,
    name: '',
    wprId: '',
    cpmProductId: 0,
    apps: [],
    isHoldback: false,
    isPublished: false
}

const TitleFieldSearch: React.FC<IProps> = ({
  hierarchyId,
  titleSuggestions,
  searchTitles,
  searchTitleVersionsByWprId,
  isLoadingTitles
}) => {  
  const [textValue, setTextValue] = React.useState('');
  const [foo, setFoo] = React.useState(false);
  const [titleSelected, setSelected] = React.useState(initialSelected);

  React.useEffect(() => {
    // if (titleSelected && titleSelected.id > 0) {
    //   searchTitleVersionsByWprId([titleSelected.wprId], textValue, hierarchyId);
    // }
  }, [titleSelected, searchTitleVersionsByWprId, textValue, hierarchyId])

  React.useEffect(() => {
    // const id = hierarchyId ?? 0;
    // if (textValue.length > 0) {
    //   searchTitles(textValue, id);
    // }
  }, [hierarchyId, searchTitles, textValue])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && textValue.trim().length >= 1) {
      event.preventDefault();
      searchTitleVersionsByWprId(textValue.split(","), textValue, hierarchyId);
    }
  }

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: string) => {
    if (reason === "input") {
      setTextValue(value);
      if (value.length > 0) {
        searchTitles(value, hierarchyId).finally(() => setFoo(!foo));
      }
    } else if (reason === "clear") {
      setTextValue("");   
      setSelected(initialSelected);   
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, value: IBaseTitle | null) => {
    console.log('handleChange');
    console.log(value);
    if (value) {
      setTextValue(prepareTitleDisplay(value as IBaseTitle));
      setSelected(value as IBaseTitle);
      searchTitleVersionsByWprId([value.cpmProductId > 0 ? value.cpmProductId.toString() : value.wprId], '', hierarchyId);
    }
  }

  const prepareTitleDisplay = (title: IBaseTitle) => {
    return title.name;
  }
  
  const handlePasteTitleSearch = (event: any) => {
    event.persist();
    event.stopPropagation()
    event.preventDefault();
    const clipboardData = event.clipboardData;
    let data = clipboardData.getData("Text");
    if (data.length > 0 && !data.includes(",")) {
      data = data.split(/\r?\n|\r/g).join(",")
    }
    
    event.target.value = data;
    setTextValue(data);
    // console.log({ data });
  }

  return (
    <Autocomplete
      id="title"
      fullWidth
      inputValue={textValue}
      value={titleSelected}
      noOptionsText='No results'
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onInputChange={handleInputChange}
      // getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => prepareTitleDisplay(option)}
      filterOptions={filterOptions}
      options={titleSuggestions}
      // open={titleSuggestions.length > 0}
      loading={isLoadingTitles}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Title"
          variant="outlined"
          placeholder="Enter Title name or Product IDs | Title IDs"
          multiline
          onPaste={handlePasteTitleSearch}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {isLoadingTitles ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default TitleFieldSearch;