export interface IAppSettings {
    apiBaseUrl: string;
    openIdDomain: string;
    authBaseUrl: string;
    clientId: string;
    apimKey: string;
    apps: portal[];   
}

export interface portal {
    id: number,
    baseUrl: string
}

export class SettingsManager {
    settings: IAppSettings;
    constructor () {
        this.settings = window['runConfig'] as IAppSettings;
    }
    
    static CALLBACK_PATH = 'auth/myidcallback';
    static AUTH_PLATFORM_PATH = '/user/info?app=dmdc';
    getRedirectUrl = () => `${window.location.protocol}//${window.location.host}/${SettingsManager.CALLBACK_PATH}`;

	getAuthProviderUrl = () => {
		const clientIdParam = `client_id=${this.settings.clientId}`;
		const responseTypeParam = "response_type=token";
		const redirectUriParam = `redirect_uri=${this.getRedirectUrl()}`;
        const nonceParam = `nonce=${new Date().getTime()}`;
		const scopeParam = "scope=openid";

		return `${this.settings.openIdDomain}?${clientIdParam}&${responseTypeParam}&${redirectUriParam}&${nonceParam}&${scopeParam}`;
	}
}