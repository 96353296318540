import { observable, action, configure, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { IGroup } from "../models/group";
import { IWorkspace } from "../models/workspace";
import { RootStore } from "./rootStore";
import { ITitle } from "../models/title";
import { ITitleVersion, TitleVersion } from "../models/titleVersion";
import { HierarchyType, HierarchyTypes } from "../models/lookupValues";
import { process, State } from '@progress/kendo-data-query';

configure({ enforceActions: "always" });
 
export default class WorkspaceStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingWorkspace = false;
  @observable isLoadingGroups = false;
  @observable isLoadingTitles = false;
  @observable isLoadingTitleVersions = false;

  @observable workspace: IWorkspace | null = null;
  @observable devIdworkspace: IWorkspace | null = null;
  @observable deliverableIds: string[] = [];
  @observable group: IGroup | null = null;
  @observable groupSuggestions: IGroup[] | [] = [];
  @observable titleSuggestions: ITitle[] | [] = [];   
  @observable hierarchyTypes: HierarchyType[] = HierarchyTypes.Values;
  @observable titleVersionFilters: State = {};
  @observable titleVersions= new Map<number, TitleVersion>();
  
  @computed get filteredTitleVersions() {           
    return process(Array.from(this.titleVersions.values()), this.titleVersionFilters);      
  }

  @computed get allTitleVersionsSelected() {
    if (this.titleVersions.values?.length > 0) {      
      const result = (Array.from(this.titleVersions.values()).findIndex(x => !x.selected) > -1);
      console.log(result);
      return result;
    } else {
      return false;
    }
  }

  @action loadWorkspace = async (id: string) => {
    this.loadingWorkspace = true;
    try {
      console.log("loading workspace for" + id);
      const workspaceResponse = await agent.Workspaces.details(id);
      runInAction("loading workspace", () => {
        this.workspace = workspaceResponse;   
        if (this.workspace.titleVersions)             
          this.preapreTitleVersionsData(this.workspace.titleVersions);
        this.loadingWorkspace = false;
      });
    } catch (error) {
      runInAction("loading workspace error", () => {
        this.loadingWorkspace = false;
      });
    }
  };

  @action createWorkspace = async (value?: IWorkspace) => {
    this.loadingWorkspace = true;
    try {
      const uniqueId = await agent.Workspaces.create((value ?? this.devIdworkspace) as IWorkspace);
      runInAction("creating workspace", () => {
        this.devIdworkspace = null;
        this.workspace = { ...(value ?? this.devIdworkspace) as IWorkspace, uniqueId: uniqueId };
        this.loadingWorkspace = false;        
      });
    } catch (error) {
      runInAction("creating workspace error", () => {
        this.loadingWorkspace = false;        
      });      
    }
  };

  @action editWorkspace = async (workspace: IWorkspace) => {
    this.workspace = workspace;
  };

  @action searchGroup = async (keyword: string) => {
    this.isLoadingGroups = true;
    try {
      const groupsResponse = await agent.Groups.search(keyword);
      runInAction("searching group", () => {
        this.groupSuggestions = groupsResponse;
        this.isLoadingGroups = false;
      });
    } catch (error) {
      runInAction("searching groups error", () => {        
        this.isLoadingGroups = false;
      });
    }
  };

  @action addInitialGroupOption = (group: IGroup) => {
    if (this.groupSuggestions.length === 0) {
      this.groupSuggestions = [group];
    }
  }

  @action searchTitles = async (keyword: string, hierarchyId: number) => {
    this.isLoadingTitles = true;
    try {
      const titlesResponse = await agent.Titles.suggest(keyword, hierarchyId);
      runInAction("searching titles", () => {
        this.titleSuggestions = titlesResponse;
        this.isLoadingTitles = false;
      });
    } catch (error) {
      runInAction("searching titles error", () => { 
        this.isLoadingTitles = false;
      });
    }
  };

  @action searchTitleVersionsByWprId = async (
    wprids: string[],
    keyword: string,
    hierarchyId: number
  ) => {
    this.isLoadingTitleVersions = true;
    try {
      const titleVersionsResponse = await agent.TitleVersions.searchByWprId(
        wprids,
        keyword,
        hierarchyId
      );
      
      runInAction("searching titleVersions", () => {        
        this.preapreTitleVersionsData(titleVersionsResponse.titleVersions);
        this.isLoadingTitleVersions = false;
      });
    } catch (error) {
      runInAction("searching titleVersions error", () => {
        this.isLoadingTitleVersions = false;        
      });
    }
  };

  @action searchTitleVersionsByIds = async (
    ids: number[],
    hierarchyId: number
  ) => {
    this.isLoadingTitleVersions = true;
    try {
      const titleVersionsResponse = await agent.TitleVersions.searchById(
        ids,
        hierarchyId
      );      
      runInAction("searching titleVersions", () => {        
        this.preapreTitleVersionsData(titleVersionsResponse.titleVersions);
        this.isLoadingTitleVersions = false;
      });
    } catch (error) {
      runInAction("searching titleVersions error", () => {
        this.isLoadingTitleVersions = false;        
      });
    }
  };


  @action searchTitleVersionsByDeliverableIds = async (): Promise<boolean> => {
    this.isLoadingTitleVersions = true;
    let workspaceFound = false;
    try {
      const titleVersionWorkspaceResponse = await agent.TitleVersions.searchByDeliverableId(
        this.deliverableIds,        
        this.devIdworkspace?.hierarchyId as number,
      );
      runInAction("searching titleVersions", () => {

        if (titleVersionWorkspaceResponse && titleVersionWorkspaceResponse.group) {
          this.devIdworkspace = {
            ...this.devIdworkspace as IWorkspace,
            group: titleVersionWorkspaceResponse.group,
            titleVersions: titleVersionWorkspaceResponse.titleVersions,
          }
          workspaceFound = true;
        }

        this.isLoadingTitleVersions = false;
      });
    } catch (error) {
      runInAction("searching titleVersions error", () => {
        this.isLoadingTitleVersions = false;        
      });
      throw error;
    }

    return workspaceFound;
  };

  @action toggleTitleVersionSelection = (id: number) => {   
    let tv = this.titleVersions.get(id);
    if (tv) {
      tv.selected = !tv.selected;
      this.titleVersions.set(id, tv);
    }
  }

  @action toggleAllTitleVersionSelection = (checked: boolean) => {
    this.titleVersions.forEach(item => item.selected = checked);
  }

  @action removeTitleVersions = async () => {
    try {
      const selectedItems = Array.from(this.titleVersions.values()).filter(x => x.selected);

      selectedItems.forEach(item => this.titleVersions.delete(item.id));

    } catch (error) {}
  };

  @action removeAllTitleVersions = () => {
    this.titleVersions.clear();   
    this.titleVersionFilters = {};
  }

  @action resetAll = () => {
    this.loadingWorkspace = false;
    this.isLoadingGroups = false;
    this.isLoadingTitles = false;
    this.isLoadingTitleVersions = false;
    this.workspace = null;
    this.group = null;
    this.groupSuggestions = [];
    this.titleSuggestions = [];
    this.titleVersions.clear();   
  }

  @action updateDeliverableIds = (ids: string[]) => {
    this.deliverableIds = ids;
  }

  @action tabChange = (devIdTab?: boolean, hierarchyId?: number) => {
    this.devIdworkspace = devIdTab ? {
      hierarchyId: hierarchyId as number,
    } : null;
    this.deliverableIds = [];
  }

  @action filterTitleVersionsData = (dataState: State) => {    
    this.titleVersionFilters = dataState;
  }

  private preapreTitleVersionsData = (data: ITitleVersion[]) => {
    this.titleVersionFilters = {};
    data.forEach((tv) => {   
      const item = new TitleVersion(tv);        
      this.titleVersions.set(tv.id, item);
    });    
  }
}
