import { IAudioAuthorization } from './../models/audioAuthorization';
import { ITitleVersion } from './../models/titleVersion';
import {observable, action, configure, runInAction} from 'mobx';
import agent from '../api/agent';
import { AuthKey, IAuthorization, Authorization, LibItem, AuthorizationType, AuthMediaIdCollection } from '../models/authorization';
import { AuthorizationRequest, PreviewAuthorizationRequest } from '../models/authorizationRequest';
import { AuthorizationsRegistry, IAuthorizationsRegistry } from '../models/authorizationsRegistry';
import { IAuthorizationTab } from '../models/authorizationTab';
import { MetadataFieldValue } from '../models/metadataFieldValue';
import { GenerateGuid } from '../utils/guidGenerator';
import { RootStore } from './rootStore';
import { IGroup } from '../models/group';
import { IAsset } from '../models/asset';
import { AuthFieldTypes } from '../enums/authFieldTypes';
import { FieldType } from '../models/field';
import { IdName } from '../models/idName';
import moment from 'moment';
import { SettingsManager } from '../utils/settingsManager';

configure({enforceActions: "always"})

export default class AuthorizationStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable loadingAuthorizations = false;
    @observable authorize = false;
    @observable explicitAsset = false;
    @observable isSavingAuthorizations = false;
    @observable authorizationsRegistry = new Map<number, IAuthorizationsRegistry>();
    @observable beforePreviewAuthorizationsRegistry = new Map<number, IAuthorizationsRegistry>();
    @observable recordsSelected = new Map<number, {
        allSelected: boolean,
        records: Map<string, boolean>
    }>();

    @observable loadingTabs = false;
    @observable tabs = new Map<number, IAuthorizationTab>();
    @observable tabStatuses = new Map<number, IAuthorizationTab>();
    @observable metadataFieldGroups: IdName[] = [];
    
    @observable deliverableId: string | null = null;
    @observable sequenceId: number | null = null;
    @observable authId: number | null = null;
    @observable applicationAssetIds: number[] = [];
    @observable isOpenedAssetsDialog = false;
    @observable isLoadingAssets = false;
    @observable assets: IAsset[] = [];

    @observable isOpenedEditDialog = false;

    @observable isInPreviewAuthMode = false;
    @observable checkPreviewAuthMode = false;
    
    @action loadTabs = async (reload: boolean) => {
        this.loadingTabs = true;
        this.tabs.clear();
        try {
            const tabsResponse = await agent.Tabs.list();
            runInAction('loading tabs',() => {
                this.metadataFieldGroups = tabsResponse.groups;
                tabsResponse.tabs.forEach((tab) => {
                    this.tabs.set(tab.id, tab);
                    this.authorizationsRegistry.set(
                        tab.id, 
                        new AuthorizationsRegistry(tab.id, new Map())
                    );
                    this.recordsSelected.set(tab.id, {
                        allSelected: false,
                        records: new Map<string, boolean>()
                    })
                })
                this.loadingTabs = false;
            })
        } catch (error) {
            runInAction('loading tabs error',() => {
                this.loadingTabs = false;
            })
        }
    }

    @action loadTabFulfillmentStatuses = async (uniqueId: string, tabId?: number) => {
        
        if (tabId === undefined)
            this.tabStatuses.clear();
            
        try {
            const tabsResponse = await agent.Tabs.fulfillmentStatuses(uniqueId);
            runInAction('loading tabs',() => {
                tabsResponse.forEach((tab) => {
                    if ((tabId === undefined) || (tab.id === tabId) ) {
                        this.tabStatuses.set(tab.id, tab);
                    }                    
                })
            })
        } catch (error) {
            runInAction('loading tabs error',() => {
                
            })
        }
    }

    /**
     * Helper method to set up the Authorizations view model to render the tab grid given the response API and the tab id
     * @param authorizationsResponse The list of authorizations to render
     * @param tabId The tab id
     */
    runInActionLoadingAuthorizations = (authorizationsResponse: IAuthorization[], tabId: number) => {        
        runInAction('loading authorizations', () => {
            authorizationsResponse.forEach((auth) => {
                if (auth.startDate){                    
                    auth.startDate = (typeof auth.startDate === 'string') ?
                        new Date(moment.parseZone(auth.startDate).format('YYYY-MM-DDTHH:mm:ss')) :
                        new Date(auth.startDate);
                }
                if (auth.endDate){
                    auth.endDate = (typeof auth.endDate === 'string') ?
                        new Date(moment.parseZone(auth.endDate).format('YYYY-MM-DDTHH:mm:ss')) :
                        new Date(auth.endDate);
                }
            });

            let registry = new AuthorizationsRegistry(tabId, new Map());
            registry.updateAuthorizations(authorizationsResponse.map(x =>{
                let newAuth = new Authorization(x.uniqueId, x.group as IGroup, x.titleVersion as ITitleVersion);
                newAuth = {...newAuth, ...x};
                return newAuth;
            }));
            registry.setFirstTimeLoad();
            this.authorizationsRegistry.set(tabId, registry);

            this.recordsSelected.set(tabId, {
                allSelected: true,
                records: new Map([...registry.authorizations.values()].map(x => [x.uniqueId, true]))
            });                        
        })
    }

    /**
     * Action to load the authorizations given the unique workspace id and the tab id
     * @param uniqueId The workspace id
     * @param tabId The tab id
     */
    @action loadAuthorizations = async (uniqueId: string, tabId: number) => {
        this.loadingAuthorizations = true;
        try {
            const authorizationsResponse = await agent.Authorizations.details(uniqueId, tabId);
            
            this.runInActionLoadingAuthorizations(authorizationsResponse, tabId);
            
            this.loadingAuthorizations = false;
            
        } catch (error) {
            runInAction('loading authorizations error', () => {
                this.loadingAuthorizations = false;
            })
        }
    };
    
    @action selectRecord = (tabId: number, uniqueId: string) => {
        const isSelected = this.recordsSelected.get(tabId)?.records.get(uniqueId);
        if (isSelected) {
            this.recordsSelected.get(tabId)?.records.delete(uniqueId);
        } else {
            this.recordsSelected.get(tabId)?.records.set(uniqueId, true)
        }
        this.recordsSelected.set(tabId, {
            records: this.recordsSelected.get(tabId)?.records as Map<any, any>,
            allSelected: false
        })
    }

    @action selectAllRecords = (tabId: number) => {
        if (this.recordsSelected.get(tabId)?.allSelected) {
            this.recordsSelected.get(tabId)?.records.clear();
        } else {
            const authorizationRegistry = this.authorizationsRegistry.get(tabId);
            authorizationRegistry?.authorizations.forEach(x => {
                if (this.isSelectable(tabId, x)) {
                    this.recordsSelected.get(tabId)?.records.set(x.uniqueId as string, true);
                }
            })

        }   

        this.recordsSelected.set(tabId, {
            records: this.recordsSelected.get(tabId)?.records as Map<any, any>,
            allSelected: !this.recordsSelected.get(tabId)?.allSelected as boolean
        })
    }

    @action addNewMetadata = (tabId: number, selectedFieldValues: MetadataFieldValue[]) => {
        const registry = this.authorizationsRegistry.get(tabId);
        let tabAuths = registry?.authorizations;
        const authKeys = [...tabAuths?.keys() ?? []];
        const auths = [...tabAuths?.values() ?? []];
        const uniqueTitleVersions = new Map<number, string>();
        const keysToDeselect: string[] = [];
        [...this.recordsSelected.get(tabId)?.records.keys() ?? []].forEach(key => {
            const auth = tabAuths?.get(key);
            const titleVersionsAuth = [...tabAuths?.values() ?? []].filter(x => x.titleVersion?.id === auth?.titleVersion?.id);
            const tVFirstAuth = titleVersionsAuth.shift();

            if (!uniqueTitleVersions.get(tVFirstAuth?.titleVersion?.id as number)) {
                uniqueTitleVersions.set(tVFirstAuth?.titleVersion?.id as number, key);
                if (auth?.type === AuthorizationType.ExplicitWithoutDeliverable) {
                    keysToDeselect.push(key);
                }
            }

        });
        
        let tmpMap = new Map();
        let tmpChangeRegistry = new AuthorizationsRegistry(tabId, new Map());
        [...registry?.changedAuthorizations.values() ?? []].forEach(x => {
            tmpChangeRegistry.changedAuthorizations.set(x.authorization.uniqueId, x);
        });

        [...uniqueTitleVersions.keys()].forEach(tvId => {
            const uniqueId = uniqueTitleVersions.get(tvId) as string;
            const auth = tabAuths?.get(uniqueId);
            let newRecord = new Authorization(
                GenerateGuid(), 
                auth?.group as IGroup, 
                auth?.titleVersion as ITitleVersion
            ).asMetadata();

            selectedFieldValues.forEach(x => {
                if (x.field.id === AuthFieldTypes.TITLE_NAME) {
                    newRecord.updateTitleName(x.textValue as string);
                } else if (x.field.id === AuthFieldTypes.BQ_SPEC_NAME) {
                    newRecord.updateBqSpec(x.dropdownValue as LibItem);
                } else if (x.field.id === AuthFieldTypes.ENTERPRISE_PROFILE_NAME) {
                    newRecord.updateEnterpriseProfile(x.dropdownValue as LibItem);
                } else if (x.field.id === AuthFieldTypes.AUDIO) {
                    newRecord.updateAudios(x.audioValues as IAudioAuthorization[]);
                }else if(x.field.id === AuthFieldTypes.TIME_ZONE){
                    newRecord.updateTimeZone(x.dropdownValue as LibItem);
                } else {
                    
                    switch (x.field.fieldType) {
                        case FieldType.Textbox:
                            let finalValue: any = x.textValue;
                            if (newRecord[x.field.binding ?? ''] instanceof Number) {
                                finalValue = +finalValue;
                            }
                            newRecord[x.field.binding ?? ''] = finalValue;
                            break;
                        case FieldType.Dropdown:
                            newRecord[x.field.binding ?? ''] = x.dropdownValue?.value;
                            break;
                        case FieldType.Date:
                            newRecord[x.field.binding ?? ''] = x.dateValue;
                            break;
                        case FieldType.DateTime:
                            newRecord[x.field.binding ?? ''] = moment(x.dateValue);
                            break;
                        default:
                            break;
                    }
                }
            });

            const titleVersionAuths = auths.filter(x => x.titleVersion?.id === tvId);
            let indexNewRecord = -1;
            let itemsToRemove = 0;

            if (auth?.type === AuthorizationType.NonExisting) {
                indexNewRecord = auths.findIndex(x => x.uniqueId === auth.uniqueId);
                itemsToRemove = 1;
                newRecord.uniqueId = auth.uniqueId;
            } else {
                const lastTitleVersionsAuth = titleVersionAuths[titleVersionAuths.length - 1]
                indexNewRecord = auths.findIndex(x => x.uniqueId === lastTitleVersionsAuth.uniqueId) + 1;
            }

            authKeys.splice(indexNewRecord, itemsToRemove, newRecord.uniqueId);
            auths.splice(indexNewRecord, itemsToRemove, newRecord);
            tmpChangeRegistry?.addNewAuthorization(newRecord);
        });
        
        if (selectedFieldValues.length > 0) {
            authKeys.forEach((x, i) => {
                tmpMap.set(x, auths[i]);
            });

            this.authorizationsRegistry.set(tabId,{
                tabId: tabId,
                isLoaded: registry?.isLoaded ?? false,
                changedAuthorizations: new Map(tmpChangeRegistry.changedAuthorizations),
                authorizations: tmpMap
            } as IAuthorizationsRegistry);
        }

        keysToDeselect.forEach(key => this.recordsSelected.get(tabId)?.records.delete(key));
        this.toggleCheckPreviewAuthMode();
    }

    @action saveAuthorizations = async (
        uniqueId: string, 
        tabId: number
    ) => {
        try {
            this.isSavingAuthorizations = true;
            const tabAuthorizations = this.authorizationsRegistry.get(tabId);
            const changedAuths = [...tabAuthorizations?.changedAuthorizations.values() ?? []]

            let authsToSave: IAuthorization[] = [];
            let authsToDelete: AuthKey[] = [];

            changedAuths.forEach(reg => {
                if (reg.isDeleted && reg.authorization.deliverableId) {
                    authsToDelete.push({
                        deliverableId: reg.authorization.deliverableId,
                        sequenceId: reg.authorization.sequenceId as number,
                        type: reg.authorization.type
                    });
                } else {

                    const authData = {...reg.authorization};

                    if(authData.startDate instanceof moment){
                        authData.startDate = (authData.startDate as any).format("YYYY-MM-DDTHH:mm:ss");
                    }else if(authData.startDate instanceof Date){
                        authData.startDate = moment(authData.startDate).format("YYYY-MM-DDTHH:mm:ss");
                    }

                    if(authData.endDate instanceof moment){
                        authData.endDate = (authData.endDate as any).format("YYYY-MM-DDTHH:mm:ss");
                    }else if(authData.endDate instanceof Date){
                        authData.endDate = moment(authData.endDate).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    
                    authsToSave.push(authData);
                }
            })

            const authorizationRequest = new AuthorizationRequest(uniqueId, tabId, authsToSave, authsToDelete);
            await agent.Authorizations.update(authorizationRequest);

            runInAction('saving authorizations',() => {
                this.isSavingAuthorizations = false;
                this.updateAuthorize(false);
                this.recordsSelected.set(tabId, {
                    allSelected: false,
                    records: new Map()
                });
                this.loadAuthorizations(uniqueId, tabId);
                this.setIsInPreviewAuthMode(false);
            })
        } catch (error) {
            runInAction('saving authorizations error', () => {
                this.isSavingAuthorizations = false;
                this.updateAuthorize(false);
                this.setIsInPreviewAuthMode(false);
            })
            throw error;
        }
    }

    @action updateAuthorize = (value: boolean) => {
        this.authorize = value;
    }

    @action deleteAuthorizations = (tabId: number): number => {
        const registry = this.authorizationsRegistry.get(tabId);
        let deliverableIdAuths: IAuthorization[] = [];
        let virtualAuths: IAuthorization[] = [];
        const uniqueTitleVersions = new Map<number, string>();

        let tmpChangeRegistry = new AuthorizationsRegistry(tabId, new Map());
        [...registry?.authorizations.values() ?? []].forEach(x => {
            tmpChangeRegistry.authorizations.set(x.uniqueId, x);
        });

        [...registry?.changedAuthorizations.values() ?? []].forEach(x => {
            tmpChangeRegistry.changedAuthorizations.set(x.authorization.uniqueId, x);
        });

        [...this.recordsSelected.get(tabId)?.records.keys() ?? []].forEach(key => {
            const auth = tmpChangeRegistry.authorizations.get(key);
            const titleVersionsAuth = [...tmpChangeRegistry.authorizations.values()].filter(x => x.titleVersion?.id === auth?.titleVersion?.id);
            const tVFirstAuth = titleVersionsAuth.shift();

            if (!uniqueTitleVersions.has(tVFirstAuth?.titleVersion?.id as number)) {
                uniqueTitleVersions.set(tVFirstAuth?.titleVersion?.id as number, key);
            }

            if (auth?.type !== AuthorizationType.NonExisting) {
                if (auth?.deliverableId) {
                    deliverableIdAuths.push(auth);
                } else {
                    virtualAuths.push(auth as IAuthorization);
                    if (tmpChangeRegistry.changedAuthorizations.has(auth?.uniqueId as string)) {
                        tmpChangeRegistry.changedAuthorizations.delete(auth?.uniqueId as string);
                    }
                }
            }
        });
        
        [...deliverableIdAuths, ...virtualAuths].forEach(x => {
            if (x.deliverableId) {
                tmpChangeRegistry.deleteAuthorization(x.uniqueId);
            }

            const tvId = [...uniqueTitleVersions.keys()].find(k => k === x.titleVersion?.id as number);
            const auths = [...tmpChangeRegistry.authorizations.values()];
            const titleVersionsLeft = auths.filter(y => y.titleVersion?.id === tvId).length;
            
            if (titleVersionsLeft > 1) {
                tmpChangeRegistry.authorizations.delete(x.uniqueId);
            } else {
                const fakeAuth = new Authorization(x.uniqueId, x.group as IGroup, x.titleVersion);
                tmpChangeRegistry.authorizations.set(x.uniqueId, fakeAuth);
            }
        });

        this.authorizationsRegistry.set(tabId,{
            tabId: tabId,
            isLoaded: registry?.isLoaded ?? false,
            changedAuthorizations: new Map(tmpChangeRegistry.changedAuthorizations),
            authorizations: new Map(tmpChangeRegistry.authorizations)
        } as IAuthorizationsRegistry);

        
        this.recordsSelected.set(tabId,{
            allSelected: false,
            records: new Map()
        });

        return [...tmpChangeRegistry.changedAuthorizations.values()].filter(x => x.isDeleted).length;
    }

    /**
     * Action to preview the authorizations (call the preview auth API) and re-load them
     * @param uniqueId The workspace id
     * @param tabId The tab id
     */
    @action previewAuthorizations = async (
        uniqueId: string, 
        tabId: number
    ) => {
        try {

            const tab = this.authorizationsRegistry.get(tabId);
            const changedAuths = [...tab?.changedAuthorizations.values() ?? []];

            if(changedAuths.length === 0){
                return;
            }

            this.loadingAuthorizations = true;
                        
            let authsToSave: IAuthorization[] = [];

            changedAuths.filter(a => !a.isDeleted).map(a=> a.authorization).forEach(a=> {

                const authData = {...a};

                if(authData.startDate instanceof moment){
                    authData.startDate = (authData.startDate as any).format("YYYY-MM-DDTHH:mm:ss");
                }else if(authData.startDate instanceof Date){
                    authData.startDate = moment(authData.startDate).format("YYYY-MM-DDTHH:mm:ss");
                }

                if(authData.endDate instanceof moment){
                    authData.endDate = (authData.endDate as any).format("YYYY-MM-DDTHH:mm:ss");
                }else if(authData.endDate instanceof Date){
                    authData.endDate = moment(authData.endDate).format("YYYY-MM-DDTHH:mm:ss");
                }                            

                authsToSave.push(authData);
            });

            const requestParameters = new PreviewAuthorizationRequest(uniqueId, tabId, authsToSave);

            const previewAuthorizationResponse = await agent.Authorizations.preview(requestParameters);            
                    
            runInAction(() => {

                if(previewAuthorizationResponse.length === 0){
                    this.loadingAuthorizations = false;
                    return;
                }
                            
                const currentAuthRegistry = this.authorizationsRegistry.get(tabId);                

                const previewAuthRegistry = new AuthorizationsRegistry(tabId)
                    .updateAuthorizations([...currentAuthRegistry?.authorizations.values() ?? []])
                    .updateChangedAuthorizations([...currentAuthRegistry?.changedAuthorizations.values() ?? []])
                    .setFirstTimeLoad();
                
                changedAuths.filter(a => !a.isDeleted).forEach(a=> {
                    var previewAuth = previewAuthorizationResponse.find(p=> p.uniqueId === a.authorization.uniqueId);
                    if(previewAuth){                    
                        previewAuthRegistry.authorizations.set(
                            a.authorization.uniqueId, 
                            {
                                ...a.authorization, 
                                mediaIds: {
                                    ...previewAuth.mediaIds,
                                    isInPreviewAuthMode: true
                                } as AuthMediaIdCollection
                            }
                        );
                        previewAuthRegistry.updateAuthorization(a.authorization.uniqueId as string);                       
                    }
                });
                this.authorizationsRegistry.set(tabId, previewAuthRegistry);

                this.loadingAuthorizations = false;
            });
            
        } catch (error) {
            runInAction('previewing authorizations error', () => {
                this.loadingAuthorizations = false;
            });
            throw error;
        }
    }    

    /**
     * Undo the state changes applied by the authorization preview command
     * @param tabId The tab id
     */
    @action cancelPreviewAuthorizations = async (tabId: number) => {
        this.loadingAuthorizations = true;
        try{
            const currentAuthRegistry = this.authorizationsRegistry.get(tabId);                
            
            const authorizationsInPreview = [...currentAuthRegistry?.authorizations.values() ?? []].filter(a=>a.mediaIds?.isInPreviewAuthMode === true);

            if(authorizationsInPreview.length > 0){

                const newAuthRegistry = new AuthorizationsRegistry(tabId)
                    .updateAuthorizations([...currentAuthRegistry?.authorizations.values() ?? []])
                    .updateChangedAuthorizations([...currentAuthRegistry?.changedAuthorizations.values() ?? []])
                    .setFirstTimeLoad();

                authorizationsInPreview.forEach(a=>{
                    newAuthRegistry.authorizations.set(
                        a.uniqueId,
                        {
                            ...a,
                            mediaIds: null
                        });
                });
    
                this.authorizationsRegistry.set(tabId, newAuthRegistry);
            }
            
            this.loadingAuthorizations = false;

        }catch(error){
            runInAction('cancel preview authorizations error', () => {                
                this.loadingAuthorizations = false;
            });
            throw error;
        }        
    }

    /**
     * Sets the isInPreviewAuthMode value
     * @param value true if the is in preview mode is turn on, otherwise false
     */
    @action setIsInPreviewAuthMode = (value: boolean) => {
        this.isInPreviewAuthMode = value;
    }

    /**
     * Toggles the checkPreviewAuthMode value
     */
    @action toggleCheckPreviewAuthMode = () => {
        let newValue = !this.checkPreviewAuthMode;
        this.checkPreviewAuthMode = newValue;
    }


    @action openEditDialog = (value: boolean) => {
        this.isOpenedEditDialog = value;
    }

    @action updateAuthorizations = (tabId: number, fieldValues: MetadataFieldValue[]) => {
        const tabRegistry = this.authorizationsRegistry.get(tabId);
        const newTabRegistry = new AuthorizationsRegistry(tabId)
            .updateAuthorizations([...tabRegistry?.authorizations.values() ?? []])
            .updateChangedAuthorizations([...tabRegistry?.changedAuthorizations.values() ?? []])
            .setFirstTimeLoad();
            
            [...this.recordsSelected.get(tabId)?.records.keys() ?? []].forEach(key => {
                let auth = {...newTabRegistry.authorizations?.get(key) as Authorization};
                let newRecord = new Authorization(
                    GenerateGuid(), 
                    auth?.group as IGroup, 
                    auth?.titleVersion as ITitleVersion
                )
    
                fieldValues.forEach(x => {
                    if(x.field.fieldType === FieldType.DateTime){
                        newRecord[x.field.binding ?? ''] = moment(x.dateValue);
                    }else if(x.field.fieldType === FieldType.Dropdown){
                        newRecord[x.field.binding ?? ''] = x.dropdownValue
                    }                    
                });

                
                newTabRegistry.authorizations.set(
                    auth.uniqueId, 
                    {
                        ...auth, 
                        startDate: newRecord.startDate ?? auth.startDate, 
                        endDate: newRecord.endDate ?? auth.endDate,
                        timeZone: newRecord.timeZone ?? auth.timeZone
                    }
                );
                newTabRegistry.updateAuthorization(auth?.uniqueId as string);
        });
        
        if (fieldValues.length > 0) {
            this.authorizationsRegistry.set(tabId, newTabRegistry);
        }
    }

    @action openAssetsDialog = (value: boolean, deliverableId?: string, sequenceId?: number, authId?: number, applicationAssetIds?: number[]) => {
        this.isOpenedAssetsDialog = value;
        if (value) {
            this.deliverableId = deliverableId as string;
            this.sequenceId = sequenceId as number;
            this.authId = authId as number;
            this.applicationAssetIds = applicationAssetIds as number[];
        } else {
            this.deliverableId = null;
            this.sequenceId = null;
            this.authId = null;
            this.applicationAssetIds = [];
        }
    }

    @action openTempHoldbacks = (mediaIds: AuthMediaIdCollection, groupId: string | undefined, titleVersionIds: string) => {
        const settingsManager = new SettingsManager();
        const applicationAssetIds = mediaIds.applicationAssetIds.join(',');

        mediaIds.applicationIds.forEach(id => {
            let portal = settingsManager.settings.apps.filter(x => x.id === id)[0];
            let url = `${portal.baseUrl}/admin/rights/videos?groupnumber=${groupId}&titleversions=${titleVersionIds}&ids=${applicationAssetIds}`; 
            window.open(url,`videorights${id}`);                                       
        });
    }

    @action loadAssets = async (deliverableId: string, sequenceId: number, authId: number, applicationAssetIds: number[]) => {
        this.isLoadingAssets = true;     
        try {            
            const assetsResponse = deliverableId || sequenceId || authId ?
                await agent.Assets.list(deliverableId, sequenceId, authId) :
                await agent.Assets.search(applicationAssetIds);

            runInAction('loading assets', () => {                
                this.assets = assetsResponse;
                this.isLoadingAssets = false;           
            })
        } catch (error) {
            runInAction('loading assets error', () => {
                this.assets = [];
                this.isLoadingAssets = false;
            })
        }
    }

    @action openExplicitAssets = (tabId: number, group: IGroup) => {
                
        const appsTitleversions = new Map<number, Map<number, number>>();        
        group.apps?.forEach(a => {
            appsTitleversions.set(a.id, new Map<number, number>());
        })

        const tabRegistry = this.authorizationsRegistry.get(tabId);        
        const selectedIds = [...this.recordsSelected.get(tabId)?.records.keys() ?? []]
        
        if (selectedIds){
            tabRegistry?.authorizations.forEach(auth => {
                if (selectedIds.indexOf(auth.uniqueId) > -1) {
                    auth.titleVersion?.title?.apps.forEach(app => {                        
                        if (appsTitleversions.has(app.id) && auth.titleVersion?.vaultVersionId) {                            
                            appsTitleversions.get(app.id)?.set(auth.titleVersion.vaultVersionId, auth.titleVersion.vaultVersionId);
                        }                            
                    });
                }
            });
        }

        const settingsManager = new SettingsManager();

        [...appsTitleversions.keys()].forEach(id => {
            let tvids = [...appsTitleversions.get(id)?.keys() ?? []].join(',');
            let portal = settingsManager.settings.apps.filter(x => x.id === id)[0];
            let url = `${portal.baseUrl}/admin/rights/videos?groupnumber=${group.id}&titleversions=${tvids}`; 
            window.open(url,`videorights${id}`);                                       
        });

        this.explicitAsset = true;
    }

    @action updateExplicitAsset = (value: boolean) => {
        this.explicitAsset = value;
    }

    @action clearAssets = () => {        
        this.assets = [];
    }
    
    @action resetAll = () => {
        this.loadingAuthorizations = true;
        this.isSavingAuthorizations = false;
        this.assets = [];
        this.authorizationsRegistry.clear();
        this.recordsSelected.clear();
        this.loadingTabs = false;
        this.loadingAuthorizations = false;
    }

    isSelectable = (tabId:number, item: IAuthorization) => {
        if (item.type === AuthorizationType.ExplicitWithoutDeliverable) {
            const tabAuthorizations = this.authorizationsRegistry.get(tabId as number);
            const auths = [...tabAuthorizations?.authorizations.values() ?? []];
            const sameTitleCount = auths.filter(x => x.titleVersion?.id === item.titleVersion?.id).length;
            if (sameTitleCount > 1) return false;
        }
    
        return true;
    }
}
