import { createContext } from 'react';
import { configure } from 'mobx';
import WorkspaceStore from './workspaceStore';
import AuthorizationStore from './AuthorizationStore';
import MetadataStore from './MetadataStore';
import AuthorizationFormStore from './AuthorizationFormStore';
import UserStore from './userStore';

configure({enforceActions: 'always'});

export class RootStore {
    authorizationStore: AuthorizationStore;
    workspaceStore: WorkspaceStore;
    metadatastore: MetadataStore;
    authorizationFormStore: AuthorizationFormStore;
    userStore: UserStore;
    constructor() {
        this.authorizationStore = new AuthorizationStore(this);
        this.workspaceStore = new WorkspaceStore(this);
        this.metadatastore = new MetadataStore(this);
        this.authorizationFormStore = new AuthorizationFormStore(this);
        this.userStore = new UserStore(this);
    }
}
 
export const RootStoreContext = createContext(new RootStore());