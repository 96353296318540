import { IAuthorization } from './authorization';

export interface IAuthorizationChangedRegistry {
    isDeleted: boolean;
    isUpdated: boolean;
    isNew: boolean;
    authorization: IAuthorization
}

export interface IAuthorizationsRegistry {
    tabId: number;
    authorizations: Map<string, IAuthorization>;
    isLoaded: boolean;
    changedAuthorizations: Map<string, IAuthorizationChangedRegistry>
    setFirstTimeLoad: () => void;
    updateAuthorizations: (auth: IAuthorization[]) => void;
    updateChangedAuthorizations: (changeAuths: IAuthorizationChangedRegistry[]) => void;
    deleteAuthorization: (uniqueId: string) => void;
    addNewAuthorization: (auth: IAuthorization) => void;
    getNewAuthorization:(auth: IAuthorization) => IAuthorizationChangedRegistry
}


export class AuthorizationsRegistry implements IAuthorizationsRegistry {
    
    tabId: number;
    authorizations: Map<string, IAuthorization>;
    isLoaded: boolean;
    changedAuthorizations: Map<string, IAuthorizationChangedRegistry>;
    
    constructor (tabId: number, authorizations?: Map<string, IAuthorization>) {
        this.tabId = tabId;
        this.authorizations = authorizations ?? new Map();
        this.isLoaded = false;
        this.changedAuthorizations = new Map();
    }

    updateAuthorizations = (auth: IAuthorization[] = []) => {
        auth.forEach(x => {
            this.authorizations.set(x.uniqueId, x);
        });
        return this;
    }

    updateChangedAuthorizations = (changeAuths: IAuthorizationChangedRegistry[] = []) => {
        changeAuths.forEach(x => {
            this.changedAuthorizations.set(x.authorization.uniqueId, x);
        });
        return this;
    }
    
    deleteAuthorization = (uniqueId: string) => {
        this.changedAuthorizations.set(uniqueId, {
            authorization: {...this.authorizations.get(uniqueId) as IAuthorization},
            isDeleted: true,
            isUpdated: false,
            isNew: false
        })
        return this;
    }

    updateAuthorization = (uniqueId: string) => {
        const changeAuth = this.changedAuthorizations.get(uniqueId) as IAuthorizationChangedRegistry;
        this.changedAuthorizations.set(uniqueId, {
            authorization: {...this.authorizations.get(uniqueId) as IAuthorization},
            isDeleted: false,
            isUpdated: true,
            isNew: changeAuth ? changeAuth.isNew : false
        })
        return this;
    }

    addNewAuthorization = (auth: IAuthorization) => {
        this.changedAuthorizations.set(auth.uniqueId, {
            authorization: {...auth},
            isDeleted: false,
            isUpdated: false,
            isNew: true
        });
        return this;
    }

    getNewAuthorization = (auth: IAuthorization): IAuthorizationChangedRegistry => {
        return {
            authorization: {...auth},
            isDeleted: false,
            isUpdated: false,
            isNew: true
        }
    }

    setFirstTimeLoad = () => {
        this.isLoaded = true;
        return this;
    }

}