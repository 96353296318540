import React, { useState } from 'react';
import agent from '../api/agent';
import { LocalStorageHelper } from "../utils/localStorage-helper";
import { useStyles } from './Reauth.page.styles';
import { useHistory } from "react-router-dom";

const Reauth: React.FC = () => {
    const [token, setToken] = useState('');
    const classes = useStyles();
    const history = useHistory();

    const handleOnClick = async () => {
        agent.Auth.authenticate(token).then(res => {
            setToken('');
            if (res.token) {
              LocalStorageHelper.setAuthToken(res.token);
              history.push("/preauth");
              return
            }
            console.log(res);
          }).catch((err) => {
            console.log(err);
          });    
    };

    return (
        <form
            onSubmit={(evt) => {
                evt.preventDefault();
                handleOnClick();
            }}
        >
            <textarea
                value={token}
                onChange={(e) => setToken(e.target.value)}
                className={classes.textareaStyle}
            ></textarea>
            <input type="submit" value="Send" className={classes.buttonStyle} />
        </form>
    );
};

export default Reauth;
