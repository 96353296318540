import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { LibItem } from '../../app/models/authorization';
import { IField } from '../../app/models/field';
import { Autocomplete } from '@material-ui/lab';

interface IProps {
  field: IField
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      // paddingTop: 40,
      paddingBottom: 25
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    marginTop: -25,
    marginRight: -18
  }
}));

const fieldStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 12,
    '& .MuiInputLabel-shrink': {
      fontSize: 16,
      marginTop: 8
    }
  }
}));

const autoCompleteStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 12,
    '& .MuiInputBase-root': {
      marginTop: 12,
    },
    '& .MuiInputBase-input': {
      fontSize: 12
    },
    '& .MuiInputLabel-shrink': {
      fontSize: 16,
      marginTop: -2.4
    }
  }
}));

const AudioTypePopover: React.FC<IProps> = ({
  field
}) => {
  const rootStore = useContext(RootStoreContext);
  const classes = useStyles();
  const fieldClasses = fieldStyles();
  const autocompleteClasses = autoCompleteStyles();
  const {
    openedId,
    audioFieldAnchorEl,
    closeAudioField,
    audioFieldValues,
    addAudioField,
    removeAudioField,
    cleanAudioFields,
    updateAudioField,
    loadLookups
  } = rootStore.metadatastore

  const handleClose = () => {
    let indexes: number[] = [];
    audioFieldValues.forEach((x,i) => {
      if (!x.config && !x.language && !x.format && !x.usage && !x.dynamicRange) {
        indexes.push(i);
      }
    })

    cleanAudioFields(indexes);
    closeAudioField(field);
  };

  const handleAddFieldClick = () => {
    addAudioField();
  }

  const handleRemoveFieldClick = (index: number) => {
    removeAudioField(index);
  }

  const handleDropdownSelect = (e: React.ChangeEvent<{ value: unknown }>, index: number, binding: string) => {
    const value = e.target.value ? e.target.value as string : '';
    updateAudioField(index, value, binding);
  }

  const handleAutocompleteSelect = (e: React.ChangeEvent<{}>, item: LibItem | null, index: number, binding: string) => {
    const finalValue = item ? item.value : null;
    updateAudioField(index, finalValue, binding);
  }

  const open = Boolean(field.id === openedId && audioFieldAnchorEl);
  const id = open ? `popover-${field.id}` : undefined;

  // const buildChannelField = (value: string) => {
  //   return (
  //     <TextField
  //       fullWidth
  //       id={`input-channel-${id}`}
  //       label={"Channel"}
  //       value={value}
  //       className={fieldClasses.root}
  //       InputLabelProps={{
  //         className: fieldClasses.root,
  //         shrink: true
  //       }}
  //       style={{
  //         marginTop: -8.9
  //       }}
  //       // onChange={(e) => handleTextChange(e, field)}
  //       // className={textFieldClasses.root}
  //     />
  //   )
  // }

  const buildDropdownField = (title: string, index: number, items: LibItem[], binding: string) => {
    return (
      <React.Fragment>
        <InputLabel shrink={false} id={`dropdown-${title}-${id}-label`} className={fieldClasses.root}>{title}</InputLabel>
        <Select
          labelId={`dropdown-${title}-${id}-label`}
          id={`dropdown-${title}-${id}`}
          className={fieldClasses.root}
          value={audioFieldValues[index][binding] ?? ''}
          
          // open={openedId === field.id && !isLoadingLookup.get(field.id)}
          // onClose={(e) => handleDropdownClose(e, field)}
          // onOpen={(e) => handleDropdownOpen(e, field)}
          fullWidth
          onChange={(e) => handleDropdownSelect(e, index, binding)}
          // value={selectedFieldValues.get(field.id)?.dropdownValue?.id ?? 0}
          // IconComponent={() => {
          //   return isLoadingLookup.get(field.id) ? <CircularProgress color="inherit" size={20} /> : <ArrowDropDownIcon />
          // }}
        >
          {[...items ?? []].map((x, i) =>{
            return <MenuItem key={`option${i}-${field.id}`} value={x.value}>{x.name}</MenuItem>
          })}
        </Select>
      </React.Fragment>
    )
  }

  const buildAutoCompleteField = (title: string, index: number, items: LibItem[], binding: string) => {
    return (
      <React.Fragment>
        <Autocomplete
          id={`autocomplete-${title}-${id}`}
          options={items}
          fullWidth
          getOptionLabel={(item) => item.name}
          value={items.find(x => x.value === audioFieldValues[index][binding]) ?? { value: '', name: '', description: '', tabId: null, isSelected: false }}
          // getOptionSelected={(option,) => }
          onChange={(e, value) => handleAutocompleteSelect(e, value, index, binding)}
          className={autocompleteClasses.root}
          renderInput={(params) => 
            <TextField 
              {...params} 
              label={title}
              InputLabelProps={{
                shrink: true,
                className: autocompleteClasses.root
              }}
            />
          }
        />
      </React.Fragment>
    )
  }

  const buildRecordCard = () => {

    if (audioFieldValues.length === 0) {
      return (
        <Paper elevation={3} style={{ margin: "0 20px 5px 20px", padding: 20 }}>
          <Grid container spacing={1} style={{ minWidth: "900px" }} justify="center">
            <Grid item>
              <h3>No Records added!</h3>
            </Grid>
          </Grid>
        </Paper>
      );
    } 

    return audioFieldValues.map((x, i) => {
      return(
        <Paper key={`paper-${i}`} elevation={3} style={{ margin: "0 20px 5px 20px", padding: 20 }}>
          <Grid container spacing={1} style={{ minWidth: "900px" }}>
            <Grid container item xs={1}>
              <Grid item xs zeroMinWidth>
                <IconButton color="primary" aria-label="clear" onClick={() => handleRemoveFieldClick(i)}>
                  <CancelIcon />
                </IconButton>
              </Grid>
              {/* <Grid item xs zeroMinWidth>
                {buildChannelField(x.channel?.toString() ?? '')}
              </Grid> */}
            </Grid>
            <Grid item xs={2}>
              {buildAutoCompleteField("Configuration", i, loadLookups(1035) ?? [], 'config')}
            </Grid>
            <Grid item xs={2}>
              {buildAutoCompleteField("Language", i, loadLookups(1034) ?? [], 'language')}
            </Grid>
            <Grid item xs={2}>
              {buildAutoCompleteField("Format", i, loadLookups(1036) ?? [], 'format')}
            </Grid>
            <Grid item xs={2}>
              {buildAutoCompleteField("Usage", i, loadLookups(1037) ?? [], 'usage')}
            </Grid>
            <Grid item xs={2}>
              {buildAutoCompleteField("Dynamic Range", i, loadLookups(1038) ?? [], 'dynamicRange')}
            </Grid>
          </Grid>   
        </Paper>
      )
    })
  }

  return (
    <div>
      <Popover
        key={id}
        id={id}
        open={open}
        anchorEl={audioFieldAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.root}
      >
        <Grid container justify="space-between" style={{ padding: 20 }}>
          <Grid>
            <Button size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} 
            // disabled={authorize || loadingAuthorizations || recordsSelected.records.size === 0}
            onClick={() => handleAddFieldClick()}
            >
              <AddIcon color="primary"/>
            </Button>
          </Grid>
          <Grid>
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => {handleClose()}}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        {buildRecordCard()}
        
      </Popover>
    </div>
  );
}


export default observer(AudioTypePopover);