export class LocalStorageHelper {

  private static jwtKey = "preauth-token-storage";  

  static getAuthToken = () => {
    return localStorage.getItem(LocalStorageHelper.jwtKey);
  }

  static setAuthToken = (token: string) => {
    return localStorage.setItem(LocalStorageHelper.jwtKey, token);
  }
  
  static clearAuthToken = () => {
    localStorage.removeItem(LocalStorageHelper.jwtKey);
  }

  static get = (name: string) => {
    return localStorage.getItem(name);
  }

  static set = (name: string, value: string) => {
    return localStorage.setItem(name, value);
  }
}