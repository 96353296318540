import { Button, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, Paper, Theme } from '@material-ui/core'
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { IGroup } from '../../app/models/group';
import { HierarchyTypes } from '../../app/models/lookupValues';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ITab } from '../../app/ui-models/tab';
import TabsBuilder from '../tabs/tabsBuilder';
import GroupTitleFields from './GroupTitleFields';
import TitleVersionsGrid from './TitleVersionsGrid';
import { Notifications } from '../notifications/Notifications'
import CloseIcon from '@material-ui/icons/Close';
import DeliverableIdFields from './DeliverableIdFields';
import { IWorkspace } from '../../app/models/workspace';

interface IProps {
  open: boolean,
  onClose: (result: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
  MuiPaper: {    
    borderTop: "10px solid #3DA8DF",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    maxWidth: "65%",
    minWidth: "900px",
    maxHeight: "780px",
    height: "90vh",
    marginTop: "60px",
    overflowY: "auto",
    lineHeight: 0,
    ["@media (max-height:771px)"]: {
      height: "85vh",
    },  
  }
}));

const WorkspaceEditor: React.FC<IProps> = ({
  open,
  onClose
}) => {
  const classes = useStyles();
  const rootStore = useContext(RootStoreContext);

  const {        
      workspace: initialFormState,
      createWorkspace,
      searchGroup,
      groupSuggestions,
      isLoadingGroups,
      hierarchyTypes,
      searchTitles,
      titleSuggestions,
      isLoadingTitles,
      searchTitleVersionsByWprId,
      filteredTitleVersions: titleVersions,      
      devIdworkspace,
      searchTitleVersionsByDeliverableIds,
      deliverableIds
  } = rootStore.workspaceStore;
  const notifications = new Notifications();

  const [group, setGroup] = useState<IGroup|null>();
  const [hierarchyId, setHierarchyId] = useState(HierarchyTypes.getDefault.id);
  
  useEffect(() => {
    if (initialFormState) {
        setGroup(initialFormState.group);
        setHierarchyId(initialFormState.hierarchyId);
    } else {
      //we're creating a new workspace...
    }                   
  }, [initialFormState]) 

  const handleClose = (saveChanges: boolean = false) => {
    if (saveChanges) {
      if (!devIdworkspace) {
        if (!group) return (notifications.error("You must select a Group"));   
        if (!hierarchyId) return (notifications.error("You must select a Hierarchy Type"));   
        if (titleVersions.data.length === 0) return (notifications.error('You must add title versions'));

        let newWorkspace: IWorkspace = {
          titleVersions: titleVersions.data,
          group: group,
          hierarchyId: hierarchyId
        }

        createWorkspace(newWorkspace).finally(() => {
          onClose(true);  
        }); 
      } else {
        if (deliverableIds.length === 0) {
          notifications.error("You must add Deliverable Id(s)");
          return;
        }
        searchTitleVersionsByDeliverableIds().then((found: boolean) => {
          if (found) {
            createWorkspace().finally(() => {
              onClose(true);  
            }); 
            return;
          }

          notifications.error("Title versions not found!")
        }).catch((error) => {
          console.log({ error });
          notifications.kill();
        });
      }
    } else {
      onClose(false);
    }              
  };

  const tabs: ITab[] = [
    { 
      label: "Enter Group and Title Info", 
      component:  <React.Fragment>
                    <GroupTitleFields 
                      groupProps={{ 
                        group: group as IGroup | undefined, 
                        groupSuggestions, 
                        searchGroup, 
                        groupCallback: setGroup as React.Dispatch<React.SetStateAction<IGroup | null>>,
                        isLoadingGroups
                      }}
                      titleProps={{
                        titleSuggestions,
                        searchTitles,
                        searchTitleVersionsByWprId,
                        isLoadingTitles
                      }}
                      hierarchyProps={{
                        hierarchyId,
                        hierarchyTypes,
                        setHierarchyId
                      }}
                    />
                    <br />
                    <TitleVersionsGrid />
                  </React.Fragment>,
      className: ""
    },
    {
      label: "Enter Deliverable ID(s)", 
      component: <DeliverableIdFields hierarchyId={hierarchyId} />, 
      className: ""
    }
  ];

  return (    
    <Paper elevation={3} className={classes.MuiPaper}>
      <React.Fragment>      
       <Grid container justify="space-between">
         <Grid item>
           <DialogTitle id="form-dialog-title" style={{ marginBottom: -20 }}>Manage authorizations</DialogTitle>
         </Grid>
         <Grid item>
           <IconButton aria-label="close" className={classes.closeButton} onClick={() => {handleClose()}}>
             <CloseIcon />
           </IconButton>
         </Grid>
       </Grid>
       <Grid container justify="space-between">
         <Grid item>
           <DialogContentText style={{ paddingTop: 5, paddingLeft: 24, paddingBottom: 15, marginBottom: 0 }}>
             Select a way to generate version title list.
           </DialogContentText>
         </Grid>
         <Grid item>
           <Button 
             variant="outlined" 
             onClick={() => { handleClose(true); }} 
             color="primary" 
             style={{ right: 40 }}
           >
             Generate Dashboard
           </Button>
         </Grid>
       </Grid>
        <TabsBuilder tabData={tabs} />
      </React.Fragment>
    </Paper>    
  )
}

export default observer(WorkspaceEditor)