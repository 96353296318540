import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2) + "!important",
      color: "#fff"
    },
    logo: {
        maxWidth: 160,
        marginTop: 14
    },
    headerTitle: {
      color: "#fff",
      marginLeft: 10,
      fontSize: 24
    }
  }),
);

const ButtonAppBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>      
      <AppBar position="static">
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Grid container justify="flex-start" alignItems="flex-end">
                <Grid item>
                  <img src='/assets/images/logo_dmdc.svg' className={classes.logo} alt='DMDCentral'/>   
                </Grid>
                <Grid item>
                  <span className={classes.headerTitle}>Authorization Dashboard</span> 
                </Grid>
              </Grid> 
            </Grid>            
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default ButtonAppBar;
