import { Container, ThemeProvider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthorizationDashboard from '../../features/authorizations/dashboard/AuthorizationDashboard';
import NavBar from '../../features/nav/NavBar';
import DMDCentralTheme from "../../themes/dmd-central.theme";
import PrivateRoute from '../auth/privateRoute';
import Login from '../auth/login';
import Home from '../../features/home/Home';
import Reauth from '../Reauth/Reauth.page';

const App: React.FC<RouteComponentProps> = ({
  location
}) => {
  
  return (
    <ThemeProvider theme={DMDCentralTheme}>
      <Fragment>
        <ToastContainer 
          position='top-right'
          hideProgressBar={true}
          newestOnTop={true}
          closeButton={false}
          pauseOnHover={true}
          closeOnClick={true}
          autoClose={2000}
          style={{ minWidth: 320, width: "auto" }}
        />
        <NavBar />
        <Container maxWidth="xl"> 
          <Switch>
            <Route path='/login' component={Login} />  
            <Route path='/auth/myidcallback' component={Login} />  
            <Route path='/reauth' component={Reauth} />
            <PrivateRoute path={['/', '/preauth/:id', '/preauth']} loginPath="/login">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route 
                  key={location.key}
                  path={['/preauth/:id','/preauth']}
                  component={AuthorizationDashboard} 
                />   
                </Switch>                             
            </PrivateRoute>
          </Switch>
        </Container>                                    
      </Fragment>
    </ThemeProvider>
  )
}

export default withRouter(observer(App));

