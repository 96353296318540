import React, { useContext } from 'react';
import {Button, Chip, makeStyles, Theme } from '@material-ui/core';
import { GridCellProps, GridHeaderCellProps, GridColumn as Column } from '@progress/kendo-react-grid'
import { ITabField } from '../../../app/models/authorizationTab'
import { AuthFieldTypes } from '../../../app/enums/authFieldTypes';
import { IAudioAuthorization } from '../../../app/models/audioAuthorization';
import { AudioAuthorizationRegistry } from '../../../app/models/audioAuthorizationRegistry';
import moment from 'moment';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { AuthMediaIdCollection, AuthorizationType } from '../../../app/models/authorization';

const cellStyles = makeStyles((theme: Theme) => ({
  titleTd: {
    display: "flex", 
    flexDirection: "column"
  },
  titleIds: {
    // marginTop: -10,
    color: "gray",
    opacity: 0.7
  },
  mediaIdBtn: {
    textDecoration: "underline", 
    fontSize: 10
  },
  mediaIdBtnPreviewAuth: {
    textDecoration: "underline", 
    fontSize: 10,
    color: "#FF5D5D",
    backgroundColor: "white",
    "&:hover, &:focus": {
      backgroundColor: "white"
    }
  },
  chip: {
    // width: "100px",
    height: "15px",
    borderRadius: "5px",
    fontSize: "8px"
  },
  redChip: {
    backgroundColor: "#C5283D"
  },
  pinkChip: {
    backgroundColor: "#FF51A4",
    marginTop: 2
  }
}));

export class AuthColumnBuilder {
  tabField: ITabField;
  headerCell: React.FunctionComponent<GridHeaderCellProps> | undefined;
  cell: React.FunctionComponent<GridCellProps> | undefined;
  cellContent: React.FunctionComponent<GridCellProps> | undefined;
  width: number;
  colClass: string | undefined;
  fieldType: AuthFieldTypes | undefined;
  levelOneTitle: string | undefined;
  levelTwoTitle: string | undefined;
  gridClasses: any;

  constructor(tabField: ITabField) {
    this.tabField = tabField;
    this.fieldType = tabField?.id;
    this.width = 100;
  }

  build = (gridClasses: any)  => {
    this.gridClasses = gridClasses;
    switch (this.fieldType) {
      case AuthFieldTypes.EP:
        this.width = 50;
        break;
      case AuthFieldTypes.DL:
        this.width = 50;
        this.colClass = this.gridClasses["divisorColumn"];
        break;
      case AuthFieldTypes.SEQ_ID:
        this.width = 65;
        break;
      case AuthFieldTypes.TITLE_NAME:
        this.headerCell = this.getTitleNameHeader;
        this.cellContent = this.getTitleNameContent;
        this.width = 110;
        break;
      case AuthFieldTypes.MEDIA_ID:
        this.cellContent = this.getMediaIdContent;
        this.colClass = this.gridClasses["divisorColumn"];
        this.width = 145;
        break;
      case AuthFieldTypes.RELEASE_DATE:
        this.cellContent = this.getDateContent;
        break;
      case AuthFieldTypes.START_DATE:
      case AuthFieldTypes.END_DATE:
        this.cellContent = this.getDateTimeContent;
        break;
      case AuthFieldTypes.AUDIO:
        this.cellContent = this.getAudiosContent; 
        break;
      case AuthFieldTypes.BQ_SPEC_NAME:
        this.levelOneTitle = "BQ Spec";
        this.levelTwoTitle = "Name";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.ENTERPRISE_PROFILE_NAME:
        this.width = 180;
        break;
      case AuthFieldTypes.CONFORMED_LANGUAGE:
        this.levelOneTitle = "Conformed Language";
        this.levelTwoTitle = "(retired)";
        this.width = 110;
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.TEXTED_LANGUAGE:
        this.levelOneTitle = "Texted";
        this.levelTwoTitle = "Language";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.MASTER_VERSION:
        this.levelOneTitle = "Delivery";
        this.levelTwoTitle = "Type";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.VIDEO_BIT_RATE:
        this.levelOneTitle = "Video Bit";
        this.levelTwoTitle = "Rate";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.SUBTITLE_LANGUAGE:
        this.levelOneTitle = "Subtitle";
        this.levelTwoTitle = "Language";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.SUBTITLE_CC_FORMAT:
        this.levelOneTitle = "Subtitle CC";
        this.levelTwoTitle = "Format (retired)";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.AUDIO_RATE:
        this.width = 60;
        this.levelOneTitle = "Audio";
        this.levelTwoTitle = "Rate";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.CANVAS_HEIGHT:
        this.width = 60;
        this.levelOneTitle = "Canvas";
        this.levelTwoTitle = "Height";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.CANVAS_WIDTH:
        this.width = 60;
        this.levelOneTitle = "Canvas";
        this.levelTwoTitle = "Width";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.CLOSED_CAPTION:
        this.levelOneTitle = "Closed Caption";
        this.levelTwoTitle = "(retired)";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.AUTHORIZED_BY:
          this.levelOneTitle = "Authorized";
          this.levelTwoTitle = "By";
          this.headerCell = this.twoLevelHeader;
          break;
      case AuthFieldTypes.PROFILE_TYPE:
        this.levelOneTitle = "Profile Type";
        this.levelTwoTitle = "(retired)";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.RESOLUTION:
          this.levelOneTitle = "Resolution";
          this.levelTwoTitle = "(retired)";
          this.headerCell = this.twoLevelHeader;
          break;
      case AuthFieldTypes.APERTURE:
          this.levelOneTitle = "Aperture";
          this.levelTwoTitle = "(retired)";
          this.headerCell = this.twoLevelHeader;
          break;
      case AuthFieldTypes.VIDEO_ASPECT_RATIO:
        this.levelOneTitle = "Video Aspect";
        this.levelTwoTitle = "Ratio";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.SUBTITLE_TYPE:
        this.levelOneTitle = "Subtitle";
        this.levelTwoTitle = "Type";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.CC_LANGUAGE:
        this.levelOneTitle = "CC";
        this.levelTwoTitle = "Language";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.DIVISION:
        this.levelOneTitle = "Division";
        this.levelTwoTitle = "(retired)";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.BROADCAST_STANDARD:
        this.levelOneTitle = "Broadcast";
        this.levelTwoTitle = "Standard";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.FILE_TYPE_DESCRIPTION:
        this.levelOneTitle = "File Type";
        this.levelTwoTitle = "Description";
        this.headerCell = this.twoLevelHeader;
        break;
      case AuthFieldTypes.TIME_ZONE:
        this.cellContent = this.getTimeZoneContent;
        break;
      default:
        break;
    }

    if (!this.cellContent && !this.tabField.binding) {
      console.log("missing binding for " + this.tabField.name);
      this.cellContent = this.getEmptyContent;
    }

    this.cell = this.prepareCell;

    return this.renderCell();
  }

  private prepareCell = (props: React.PropsWithChildren<GridCellProps>) => {

    const uniqueId = props.dataItem["uniqueId"];
    const {
      selectRecord,
    } = this.getRootStore().authorizationStore;

    const fieldTypesToCheck = [AuthFieldTypes.EP, AuthFieldTypes.TITLE_NAME];
    let content: JSX.Element = this.cellContent ? this.cellContent(props) : this.getValueByBinding(props);

    if (fieldTypesToCheck.includes(this.tabField.id as number)) {
      if (this.isInSameGroup(uniqueId)) {
        content = this.getEmptyContent(props);
      }
    }

    return (
      <td width={this.width} className={this.colClass} onClick={this.onSelectableRecordGetEvent(props, selectRecord)}>        
        {content}
      </td>
    );
  }

  private getEmptyContent = (props: React.PropsWithChildren<GridCellProps>) => {
    return (
      <span></span>
    );
  }

  private getTitleNameHeader = (props: any) => {
    const classes = cellStyles();

    return (
      <a className={`k-link ${classes.titleTd}`} style={{ display: "flex" }} onClick={props.onClick}>
        <span style={{ marginBottom: -8 }}>Title Name</span>
        <small>Product ID - (Title ID)</small>
        {props.children}
      </a>
    )
  }

  private twoLevelHeader = (props: any) => {
    const classes = cellStyles();

    return (
      <a className={`k-link ${classes.titleTd}`} style={{ display: "flex" }} onClick={props.onClick}>
        <span style={{ marginBottom: -8 }}>{this.levelOneTitle}</span>
        <span>{this.levelTwoTitle}</span>
        {props.children}
      </a>
    )
  }

  private getTitleNameContent = (props: React.PropsWithChildren<GridCellProps>) => {
    const classes = cellStyles();
    const productId = props.dataItem["titleVersion"]["title"]["cpmProductId"];
    const wrpId = props.dataItem["titleVersion"]["title"]["wprId"];
    const isHholdback = Boolean(props.dataItem["titleVersion"]["title"]["isHoldback"] ?? false);
    const isPublished = Boolean(props.dataItem["titleVersion"]["title"]["isPublished"] ?? false);

    return (
      <div className={classes.titleTd}>
        <span>{this.getValueByBinding(props)}</span>
        <span className={classes.titleIds}>
          {productId} - ({wrpId})
        </span>
        {isHholdback ? <Chip label="Holdback" color="secondary" className={`${classes.chip} ${classes.redChip}`} /> : ""}
        {!isPublished ? <Chip label="Not Published" color="secondary" className={`${classes.chip} ${classes.pinkChip}`} /> : ""}
      </div>
    );
  }

  private getDateContent = (props: React.PropsWithChildren<GridCellProps>) => {
    let value: string | Date = "";
    const cellValue = this.getValueByBinding(props);

    if (cellValue) {
      value = moment(cellValue).format("DD MMM YYYY");
    }

    return (<span>{value}</span>);
  }

  private getDateTimeContent = (props: React.PropsWithChildren<GridCellProps>) => {
    let value: string | Date = "";
    const cellValue = this.getValueByBinding(props);

    if (cellValue) {
      value = moment(cellValue).format("DD MMM YYYY HH:mm");
    }

    return (<span>{value}</span>);
  }

  private getTimeZoneContent = (props: React.PropsWithChildren<GridCellProps>) => {
    let value: string | null = null;
    const cellValue = this.getValueByBinding(props);

    if (cellValue) {
      value = cellValue?.name ?? '';
    }

    return (<span>{value}</span>);
  }

  private getMediaIdContent = (props: React.PropsWithChildren<GridCellProps>) => {
    let mediaIdsCollection = (this.getValueByBinding(props) ?? []) as AuthMediaIdCollection;
    
    const workspaceStore = this.getRootStore().workspaceStore;
    const titleVersionIds = [...workspaceStore.titleVersions.values()].map(tv=>tv.vaultVersionId).join(',');

    if(mediaIdsCollection.caption && mediaIdsCollection.caption.length > 0) {
      const classes = cellStyles();

      let clickHandler: any;
      const { openAssetsDialog, openTempHoldbacks } = this.getRootStore().authorizationStore;

      if(mediaIdsCollection.isTempHoldback){
        clickHandler = () => openTempHoldbacks(mediaIdsCollection, workspaceStore.workspace?.group?.id, titleVersionIds);
      }
      else {
        const deliverableId = this.getValueByBinding(props, "deliverableId") as string;
        const sequenceId = this.getValueByBinding(props, "sequenceId") as number;
        const authId = this.getValueByBinding(props, "id") as number;          
        
        clickHandler = () => openAssetsDialog(true, deliverableId, sequenceId, authId, mediaIdsCollection.applicationAssetIds);
      }

      return (
        <Button onClick={clickHandler} color="primary"
          className={mediaIdsCollection.isInPreviewAuthMode ? classes.mediaIdBtnPreviewAuth : classes.mediaIdBtn}>
          <b>{mediaIdsCollection.caption}</b>
        </Button>
      );
    }

    return this.getEmptyContent(props);

    
  }

  private getAudiosContent = (props: React.PropsWithChildren<GridCellProps>) => {
    let audioValues = (this.getValueByBinding(props, "audios") ?? []) as IAudioAuthorization[];

    return (
      <span>
        {new AudioAuthorizationRegistry(audioValues).displayAudioTextValue()}
      </span>
    );
  }

  private renderCell = () => {
    return(
      <Column 
        key={`${this.tabField.tabId}-${this.tabField.binding}-${this.tabField.id}`} 
        field={this.tabField.binding} width={this.width} title={this.tabField.name}
        headerCell={this.headerCell} 
        headerClassName={this.colClass}
        cell={this.cell}
      />
    );
  }

  private getValueByBinding = (props: React.PropsWithChildren<GridCellProps>, binding?: string) => {
    let tmpValue: any;
    const field = binding ?? props.field;

    field?.split(".").forEach(x => {
      tmpValue = tmpValue ? tmpValue[x] : props.dataItem[x];
    })

    return tmpValue;
  }

  private isInSameGroup = (uniqueId: string): boolean => {
    let result = false;
    const { authorizationsRegistry } = this.getRootStore().authorizationStore

    const auths = [...authorizationsRegistry.get(this.tabField.tabId)?.authorizations.values() ?? []];
    const authIndex = auths.findIndex(x => x.uniqueId === uniqueId);
    const wprId = auths[authIndex].titleVersion?.title?.wprId;
     const cpmProductId = auths[authIndex].titleVersion?.title?.cpmProductId;
    const lastAuth = auths[authIndex - 1];

    if (authIndex !== 0 && lastAuth) {     
      if (lastAuth.titleVersion?.title?.cpmProductId != null) {
        result = lastAuth.titleVersion?.title?.cpmProductId === cpmProductId;
      }
      else if (lastAuth.titleVersion?.title?.wprId != null) {
        result = lastAuth.titleVersion?.title?.wprId === wprId;
      }
    }

    return result;
  }

  private getRootStore = () => {
    return useContext(RootStoreContext);
  }

  private onSelectableRecordGetEvent = (props: React.PropsWithChildren<GridCellProps>, action: (tabId: number, uniqueId: string) => void) => {
    const uniqueId = props.dataItem["uniqueId"];
    const type = props.dataItem["type"] as AuthorizationType;

    const cellsWithoutClickEvent = [AuthFieldTypes.MEDIA_ID]

    if (type === AuthorizationType.ExplicitWithoutDeliverable || cellsWithoutClickEvent.includes(this.tabField.id as number)) {
      return undefined;
    }

    return () => action(this.tabField.tabId, uniqueId);
  }

}