import { IField } from "./field";

export interface ILookupValue {
  id: number;
  name: string;
  description?: string;
}

export class HierarchyType implements ILookupValue {
  id: number = 0;
  name: string = "";
  description?: string = "";

  constructor(id: number, name: string, description?: string) {
    this.id = id;
    this.name = name;
    this.description = description ?? this.description;
  }
}

export class LookupValues {
  field: IField | null;
  lib: ILookupValue[];

  constructor() {
    this.field = null;
    this.lib = [];
  }
}

export class HierarchyTypes {
  static Values: HierarchyType[] = [
    new HierarchyType(100, "Master Hierarchy"),
    new HierarchyType(4, "TVD Sales over Broadcast"),
  ];

  static getDefault: HierarchyType = HierarchyTypes.Values[0];
}
