import { IAudioAuthorization } from './audioAuthorization';
import { FieldType, IField } from './field';
import { LibItem } from './authorization';

export class MetadataFieldValue {
  field: IField;
  textValue?: string;
  dropdownValue?: LibItem;
  multipleValues?: LibItem[];
  audioValues?: IAudioAuthorization[]
  dateValue?: Date

  constructor(field: IField, value: string | LibItem | LibItem[] | IAudioAuthorization[] | Date) {
    this.field = field;
    switch (field.fieldType) {
      case FieldType.Textbox:
        this.textValue = value as string;
        break;
      case FieldType.Dropdown:
        this.dropdownValue = value as LibItem;
        break;
      case FieldType.Multiselect:
        this.multipleValues = value as LibItem[];
        break;
      case FieldType.Audio:
        this.audioValues = value as IAudioAuthorization[];
        break;
      case FieldType.Date:
        this.dateValue = value as Date;
        break;
      case FieldType.DateTime:
        this.dateValue = value as Date;
        break;
      default:
        break;
    }
  }

}