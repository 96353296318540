export enum TabTypes {
  VIDEO = 1,
  DISCRETE_AUDIO,
  IMF,
  SUBTITLES,
  CLOSED_CAPTIONS,
  DUBBING_CARDS,
  LOCALIZATION_REPORTS,
  SCRIPTS,
  HDR_METADATA
}