import { Backdrop, Button, Grid } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { LoadingIndicator } from '../../../app/layout/LoadingIndicator';
import { RouteComponentProps } from 'react-router';
import WorkspaceEditor from '../../workspace/WorkspaceEditor';
import AuthorizationTabs from '../tabs/authorizationTabs';

interface DetailsParam {
    id: string;
}

const AuthorizationDashboard: React.FC<RouteComponentProps<DetailsParam>> = ({
    match,
    history
}) => {
    const rootStore = useContext(RootStoreContext);    
    const [open, setOpen] = React.useState(false);

    const {        
        loadWorkspace,
        loadingWorkspace,
        workspace
    } = rootStore.workspaceStore;

    const {
      loadingTabs,   
      loadTabs,
      loadTabFulfillmentStatuses,
      isSavingAuthorizations,
      updateAuthorize,
      updateExplicitAsset,
      setIsInPreviewAuthMode
    } = rootStore.authorizationStore;

    useEffect(() => {        
        if (match.params.id) {
            loadWorkspace(match.params.id);
            loadTabs(false);
            loadTabFulfillmentStatuses(match.params.id)
        } else {
          setOpen(true);                      
        }
    }, [loadWorkspace, match.params.id, history, loadTabs])

    const handleClickOpen = () => {
      setIsInPreviewAuthMode(false);
      setOpen(true);
    };
  
    const handleClose = (result: boolean) => {
      setOpen(false);
      if (result && rootStore.workspaceStore.workspace?.uniqueId) {
        history.push(`/preauth/${rootStore.workspaceStore.workspace?.uniqueId}`)
      }
    };

    const handleClickAuthorize = () => {
      updateAuthorize(true);
    }

    const handleClickExplicit = () => {
      updateExplicitAsset(true);      
    }

    // const handleDashboardClose = () => {
    //   rootStore.authorizationStore.resetAll();
    //   rootStore.workspaceStore.resetAll();
    //   history.push('/preauth');
    // }

    const getGroupInfo = () => {
      if (workspace && workspace.group){
        return (<h3 style={{ marginBottom: '0' }}>{`${workspace.group.id} ${workspace.group.name}`}</h3>);
      }
      return "";
    }

    const getTitleVersionsInfo = () => {
      if (workspace && workspace.titleVersions !== null){
        return (<span>{`${workspace?.titleVersions?.length}`} Title Versions</span>);
      }
      return "";
    }
    
    if (loadingWorkspace || loadingTabs ) return <LoadingIndicator />

    return (
      <React.Fragment>
        
        {open && 
          <Backdrop open={open}>
            <WorkspaceEditor open={open} onClose={handleClose} />
          </Backdrop>
        }
        
        {!open && 
        
        <React.Fragment>
          <Grid justify="space-between" container alignItems="center" style={{ marginBottom: '15px' }}>
            <Grid item>             
                { getGroupInfo() }
                { getTitleVersionsInfo() }
            </Grid>

            <Grid item>
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleClickExplicit}
                disabled={isSavingAuthorizations}>
                  Explicit Assets
              </Button>
              &nbsp; &nbsp;
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleClickOpen} 
                disabled={isSavingAuthorizations}>
                  Edit Title Versions
              </Button>
              &nbsp; &nbsp;
              <Button 
                variant="outlined" 
                color="primary" 
                disabled={isSavingAuthorizations || !workspace?.uniqueId} 
                onClick={handleClickAuthorize}>
                  Save
              </Button>
              {/* &nbsp; &nbsp;
              <Button 
                variant="outlined" 
                disabled={isSavingAuthorizations || !workspace?.uniqueId}
                onClick={handleDashboardClose}>
                  Close
              </Button> */}
            </Grid>
          </Grid>
          
          { workspace?.group ? <span style={{ fontStyle: "italic" }}>Select an asset group to view and edit records.</span> : ""}
          { workspace?.group ? <AuthorizationTabs workspace={workspace} /> : "" }
        </React.Fragment>
            }
      </React.Fragment>
    )
}

export default observer(AuthorizationDashboard);
