export enum AuthFieldTypes {
  EP = 1001,
  TITLE_NAME = 1002,
  VERSION_DESC = 1003,
  VERSION_ID = 1004,
  MEDIA_ID = 1005,
  DL = 1006,
  BQ_SPEC_NAME = 1007,
  ENTERPRISE_PROFILE_NAME = 1059,
  CONFORMED_LANGUAGE = 1010,
  TEXTED_LANGUAGE = 1011,
  MASTER_VERSION = 1016,
  VIDEO_BIT_RATE = 1018,
  SUBTITLE_LANGUAGE = 1020,
  SUBTITLE_CC_FORMAT = 1022,
  AUDIO_RATE = 1023,
  CANVAS_HEIGHT = 1025,
  CANVAS_WIDTH = 1026,
  CLOSED_CAPTION = 1027,
  RELEASE_DATE = 1039,
  START_DATE = 1040,
  END_DATE = 1041,
  TIME_ZONE = 1060,
  AUDIO = 1042,
  SEQ_ID = 1046,
  AUTHORIZED_BY = 1047,
  PROFILE_TYPE = 1013,
  RESOLUTION = 1017,
  APERTURE = 1009,
  VIDEO_ASPECT_RATIO = 1048,
  SUBTITLE_TYPE = 1021,
  CC_LANGUAGE = 1028,
  DIVISION = 1012,
  BROADCAST_STANDARD = 1055,
  FILE_TYPE_DESCRIPTION = 1051
}