import { DiscreteAudioMetadataValidator } from './../validators/discreteAudioMetadataValidator';
import { VideoMetadataValidator } from './../validators/videoMetadataValidator';
import { TabTypes } from './../enums/tabTypes';
import { IValidator } from './../validators/IValidator';
import { IAudioAuthorization } from './../models/audioAuthorization';
import { AudioAuthorizationRegistry } from '../models/audioAuthorizationRegistry';
import { LibItem } from './../models/authorization';
import { Field, IField } from './../models/field';
import { RootStore } from './rootStore';
import { action, observable, runInAction } from 'mobx';
import { MetadataFieldValue } from '../models/metadataFieldValue';
import agent from '../api/agent';
import { AuthFieldTypes } from '../enums/authFieldTypes';

export default class MetadataStore {
  RootStore: RootStore;
  constructor(rootstore: RootStore) {
    this.RootStore = rootstore
  }

  @observable lookupValuesRegistry = new Map<number, LibItem[]>();
  @observable isLoadingLookups = false;
  @observable isLoadingPreset = false;
  @observable selectedFieldValues = new Map<number, MetadataFieldValue>();  
  @observable audioFieldValues: IAudioAuthorization[] = [];
  @observable openedId?: number | null = null;
  @observable audioFieldAnchorEl?: HTMLDivElement | null = null;
  @observable audioFieldTextValue: string = '';
  @observable missingrequiredFields = new Map<number, boolean>();
  @observable showAlertPopup = false;
  @observable alertAnchorEl: HTMLButtonElement | null = null;
  @observable alertPopupMessage: string[] | null = null;

  @action loadLookups = (fieldId: number, tabId?: number) => {
    let lookupValues = this.lookupValuesRegistry.get(fieldId);
    
    if(lookupValues)
      return lookupValues.filter(x => x.tabId == null || x.tabId == tabId);
    return [];
  }

  @action loadSelectedLookups = (field: IField, tabId: number) => {

    const selectedValue = this.selectedFieldValues.get(field.id)?.dropdownValue;

    if (!selectedValue) {
      const lib = this.lookupValuesRegistry.get(field.id);
      if (lib) {                        
        const tabDefaultValues = lib.filter(y => y.isSelected === true && y.tabId === tabId);
        const defaultValue = tabDefaultValues[0] ?? lib.find(y => y.isSelected === true) ?? null;

        if (defaultValue) {
          this.selectedFieldValues.set(field.id, new MetadataFieldValue(field, defaultValue));
        }
        
        return defaultValue;                                
      }
    } else {
      return selectedValue;
    }

    return null;
  }

  @action loadLookupValues = async () => {
    try {
      this.isLoadingLookups = true;
      const response = await agent.Lookups.list();
      runInAction('loading lookup values', () => {
        response.forEach(x => {
          this.lookupValuesRegistry.set(x.field.id, x.lib)                          
        });
        this.isLoadingLookups = false;
      }) 
    } catch (error) {
      runInAction('loading lookup values error', () => {
        this.isLoadingLookups = false;
      })
    }
  }

  @action loadPreset = async (presetId: number) => {
    var result = {};

    try {
      this.isLoadingPreset = true;
      const response = await agent.presets.details(presetId);
      
      runInAction('loading preset values', () => {
        result = response;
        this.isLoadingPreset = false;
      }) 
    } catch (error) {
      runInAction('loading preset values error', () => {
        this.isLoadingPreset = false;
      })
    }

    return result;
  }

  @action clearLookupValues = () => {
    this.lookupValuesRegistry.clear();
  }

  @action validaterequiredFields = (tabId: number, fields: Field[]) => {

    const customIsRequiredMap = new Map();
    if(tabId == TabTypes.VIDEO) {
      customIsRequiredMap.set(AuthFieldTypes.CONFORMED_LANGUAGE, (metadataFieldValues: Map<number, MetadataFieldValue>)=>{
        let textedLanguage = metadataFieldValues.get(AuthFieldTypes.TEXTED_LANGUAGE);
        debugger;
        if(textedLanguage && textedLanguage.dropdownValue?.name?.includes("Textless")){
          return false;
        }
        return true;
      });
    }
    else if(tabId == TabTypes.DISCRETE_AUDIO) {
      customIsRequiredMap.set(AuthFieldTypes.TEXTED_LANGUAGE, (metadataFieldValues: Map<number, MetadataFieldValue>)=>{
        let bqField = metadataFieldValues.get(AuthFieldTypes.BQ_SPEC_NAME);
        debugger;
        if(bqField && (bqField.dropdownValue?.name?.includes("M&E") || bqField.dropdownValue?.name?.includes("ME"))){
          return false;
        }
        return true;
      });
    }

    let result = true;
    fields.filter(x => x.isRequired).forEach(x => {
      let isRequiredCheck = customIsRequiredMap.get(x.id);
      if(isRequiredCheck && !isRequiredCheck(this.selectedFieldValues))
        return;

      const id = x.id as number;
      if (this.selectedFieldValues.has(id)) {
        this.missingrequiredFields.delete(id);
      } else {
        result = false;
        this.missingrequiredFields.set(id, true);
      }
    })

    return result;
  }

  @action softValidateMetadataFields = (tabId: number, anchorElement: HTMLButtonElement): boolean => {
    let validator: IValidator<MetadataFieldValue> | null = null;
    let metadataFieldValues: MetadataFieldValue[] = [];
    let result = true;

    [...this.selectedFieldValues.values()].forEach(x => metadataFieldValues.push(x));

    switch (tabId) {
      case TabTypes.VIDEO:
        validator = new VideoMetadataValidator(metadataFieldValues);
        break;
      case TabTypes.DISCRETE_AUDIO:
        validator = new DiscreteAudioMetadataValidator(metadataFieldValues);
        break;
      default:
        break;
    }

    if (validator && !validator.validate()) {
      this.alertPopupMessage = validator.messages;
      this.showAlertPopup = true;
      this.alertAnchorEl = anchorElement;
      result = false;
    }

    return result;
  }

  @action closeSoftValidationAlertPopup = () => {
    this.showAlertPopup = false;
    // this.alertPopupMessage = null;
    // this.alertAnchorEl = null;
  }

  @action updateFieldValue = (field: IField, value?: string | LibItem | LibItem[] | any[] | Date | null) => {
    try {
      if (!value) {
        this.selectedFieldValues.delete(field.id);
      } else {
        this.selectedFieldValues.set(field.id, new MetadataFieldValue(field, value))
      }
    } catch (error) {
      console.error(error);
    }
  }
 
  @action clearFieldValues = () => {
    console.log('Clearing field values');
    this.audioFieldValues = [];
    this.selectedFieldValues.clear();    
    this.audioFieldTextValue = '';
    this.missingrequiredFields.clear();
    this.showAlertPopup = false;
    this.alertPopupMessage = null;
  }

  @action openDropdown = (fieldId: number, open?: boolean) => {
    if (open) {
      this.openedId = fieldId;
    } else {
      this.openedId = null;
    }
  }

  @action openAudioField = (fieldId: number, anchorElement: HTMLDivElement) => {
    this.openedId = fieldId;
    this.audioFieldAnchorEl = anchorElement;
  }

  @action closeAudioField = (field: IField) => {
    this.openedId = null;
    this.audioFieldAnchorEl = null;
    if (this.audioFieldValues.length > 0) {
      this.selectedFieldValues.set(field.id, new MetadataFieldValue(field, [...this.audioFieldValues]))
    } else {
      this.selectedFieldValues.delete(field.id);
    }

    this.audioFieldTextValue = new AudioAuthorizationRegistry(this.audioFieldValues).displayAudioTextValue();

    console.log({ 
      selectedFieldValue: this.selectedFieldValues.get(field.id), 
      audioFieldValue: this.audioFieldValues 
    });
  }

  @action addAudioField = () => {
    const length = this.audioFieldValues.length;
    const channel = length > 0 ? (this.audioFieldValues[length - 1].channel ?? 0) + 1 : 1;
    this.audioFieldValues.push({
      channel: channel,
      config: '',
      language: '',
      format: '',
      dynamicRange: '',
      sequenceId: 0,
      usage: '',
      deliverableId: null
    })
  }

  @action removeAudioField = (index: number) => {
    this.audioFieldValues.splice(index, 1);
  }

  @action cleanAudioFields = (indexes: number[]) => {
    indexes.forEach(x => this.audioFieldValues.splice(x, 1));
  }

  @action updateAudioField = (index: number, value: string | null, binding: string) => {
    this.audioFieldValues[index][binding] = value;
  }

  private sleep = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }
}