import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import { Notifications } from '../notifications/Notifications';

interface IProps {
  tabId: number,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      paddingBottom: 25
    },
  }
}));

const SoftValidationAlertPopover: React.FC<IProps> = ({
  tabId,
  setOpen
}) => {

  const rootStore = useContext(RootStoreContext);
  const classes = useStyles();

  const {
    addNewMetadata
  } = rootStore.authorizationStore

  const {
    selectedFieldValues,
    clearFieldValues,
    showAlertPopup,
    alertPopupMessage,
    alertAnchorEl,
    closeSoftValidationAlertPopup
  } = rootStore.metadatastore

  const notifications = new Notifications();

  const handleClose = (result?: boolean) => {
    if (result){
      addNewMetadata(tabId, [...selectedFieldValues.values()]);
      notifications.success('Metadata added succesfully!');
      setOpen(false);
      clearFieldValues();
    }
    closeSoftValidationAlertPopup()
  };

  return (
    <div>
      <Popover
        id="confirm-popover"
        open={showAlertPopup}
        anchorEl={alertAnchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.root}
      >
        <Grid container style={{ maxWidth: 600 }}>
          <Grid item xs={12} style={{ padding: "10px 20px" }}>
            <p>{alertPopupMessage}</p>
          </Grid>
          <Grid item container justify="flex-end" style={{ padding: "0 20px 20px 20px" }}>
            <Grid item>
              <Button size="small" variant="outlined" onClick={() => handleClose()}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button size="small" variant="outlined" color="primary" onClick={() => handleClose(true)}>
                Ok
              </Button>
            </Grid>
          </Grid> 
        </Grid>
               
      </Popover>
    </div>
  );
}


export default observer(SoftValidationAlertPopover);