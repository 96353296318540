import { AuthKey, IAuthorization } from './authorization';

export interface IDeletedItems {
  deliverableId: string;
  sequenceId: number
}

export interface IAuthorizationRequest {
  uniqueId: string;
  tabId: number;
  auths: IAuthorization[];
  deletedItems: AuthKey[] | null;
}

export class AuthorizationRequest implements IAuthorizationRequest {
  uniqueId: string;
  tabId: number;
  auths: IAuthorization[];
  deletedItems: AuthKey[] | null;

  constructor(
    uniqueId: string, 
    tabId: number, 
    auths: IAuthorization[], 
    itemsToDelete: AuthKey[] | null = null
  ) {
    this.uniqueId = uniqueId;
    this.tabId = tabId;
    this.auths = auths || [];
    this.deletedItems = itemsToDelete;
  }
}

/**
 * Represents the contract type to perform an authorization preview
 */
export interface IPreviewAuthorizationRequest {
  uniqueId: string;
  tabId: number;
  auths: IAuthorization[];
}

/**
 * Represents the parameters to perform an authorization preview
 */
export class PreviewAuthorizationRequest implements IPreviewAuthorizationRequest {
  uniqueId: string;
  tabId: number;
  auths: IAuthorization[];

  constructor(uniqueId: string, tabId: number, auths: IAuthorization[]) {
    this.uniqueId = uniqueId;
    this.tabId = tabId;
    this.auths = auths || [];    
  }
}