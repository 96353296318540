import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

export const LoadingIndicator: React.FC<{content?: string}> = ({
    content
}) => {
    return (
        <Backdrop open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
