import { IAudioAuthorization } from "./audioAuthorization";
import { IGroup } from "./group";
import { ITitleVersion } from "./titleVersion";
import { ITitle } from "./title";

export enum AuthorizationType {
  Metadata = 0, // selectable -> deleteable
  MetadataMediaId = 1, // selectable -> deleteable
  ExplicitWithDeliverable = 2, // selectable -> deleteable
  ExplicitWithoutDeliverable = 3, // not selectable -> not deleteable
  NonExisting = 4 // selectable -> not deleteable
}

export interface IAuthorization {
  id: number | null;
  aperture: string | null;
  assetName: string | null;
  assetType: string | null;
  audioBitRate: string | null;
  audioCodec: string | null;
  audios: IAudioAuthorization[] | null;
  authorizedBy: string | null;
  bqSpec: LibItem | null;
  enterpriseProfile: LibItem | null;
  canvasAspectRatio: string | null;
  canvasHeight: string | null;
  canvasWidth: string | null;
  closedCaption: string | null;
  closedCaptionLanguage: string | null;
  closedCaptionSubtitleFileFormat: string | null;
  codec: string | null;
  colorSpace: string | null;
  conformedLanguage: string | null;
  contentType: string | null;
  dateImported: string | null;
  deliverableId: string | null;
  division: string | null;
  downloadsCount: number | null;
  dubbedLanguage: string | null;
  endDate: Date | string | null;
  filename: string | null;
  frameRate: string | null;
  group?: IGroup | null;
  hdrMetadata: string | null;
  language: string | null;
  masterVersion: string | null;
  mediaIds: IAuthMediaIdCollection | null;
  profileType: string | null;
  resolution: string | null;
  sequenceId: number | null;
  startDate: Date | string | null;
  subAssetType: string | null;
  subtitleLanguage: string | null;
  subtitleType: string | null;
  textedLanguage: string | null;
  timeZone: LibItem | null;
  titleVersion: ITitleVersion | null;
  type: AuthorizationType;
  uniqueId: string;
  uuid: string | null;
  versionTypeDescription: string | null;
  videoBitRate: string | null;
  videoDynamicRange: string | null;
  videoDynamicRangeFormat: string | null;
  virtualRecord?: boolean | null;

  updateTitleName: (name: string) => void;
  updateBqSpec: (lookupValue: LibItem) => void;
  updateEnterpriseProfile: (lookupValue: LibItem) => void;
  updateAudios: (audioValues: IAudioAuthorization[]) => void;
  updateTimeZone: (timeZone: LibItem) => void;
}

export class Authorization implements IAuthorization {
  id: number | null = null;
  uniqueId: string;
  type: AuthorizationType;
  authorizedBy: string | null = null;
  group?: IGroup | null | undefined = null;
  titleVersion: ITitleVersion | null = null;
  mediaIds: AuthMediaIdCollection | null = null;
  deliverableId: string | null = null;
  sequenceId: number | null = null;
  downloadsCount: number | null = null;
  startDate: Date | string | null = null;
  endDate: Date | string | null = null;
  timeZone: LibItem | null = null;
  textedLanguage: string | null = null;
  subtitleLanguage: string | null = null;
  aperture: string | null = null;
  canvasAspectRatio: string | null = null;
  resolution: string | null = null;
  videoBitRate: string | null = null;
  frameRate: string | null = null;
  codec: string | null = null;
  profileType: string | null = null;
  assetName: string | null = null;
  assetType: string | null = null;
  audioBitRate: string | null = null;
  audioCodec: string | null = null;
  canvasHeight: string | null = null;
  canvasWidth: string | null = null;
  closedCaption: string | null = null;
  conformedLanguage: string | null = null;
  contentType: string | null = null;
  dateImported: string | null = null;
  division: string | null = null;
  filename: string | null = null;
  language: string | null = null;
  masterVersion: string | null = null;
  bqSpec: LibItem | null = null;
  enterpriseProfile: LibItem | null = null;
  versionTypeDescription: string | null = null;
  dubbedLanguage: string | null = null;
  subAssetType: string | null = null;
  subtitleType: string | null = null;
  closedCaptionSubtitleFileFormat: string | null = null;
  closedCaptionLanguage: string | null = null;
  colorSpace: string | null = null;
  videoDynamicRange: string | null = null;
  videoDynamicRangeFormat: string | null = null;
  hdrMetadata: string | null = null;
  uuid: string | null = null;
  audios: IAudioAuthorization[] | null = null;
  virtualRecord?: boolean | null;

  constructor(uniqueId: string, group: IGroup | null, titleVersion: ITitleVersion | null) {
    this.uniqueId = uniqueId;
    this.type = AuthorizationType.NonExisting;
    this.group = {...group, apps: [...group?.apps ?? [] ] } as IGroup;
    this.titleVersion = {...titleVersion ?? {}, title: {...titleVersion?.title ?? {} }} as ITitleVersion;
  }
  
  updateTitleName = (name: string) => {
    let title = this.titleVersion?.title;
    (title as ITitle).name = name;
    (this.titleVersion as ITitleVersion).title = title;
  }
  
  updateBqSpec = (lookupValue: LibItem) => {
    this.bqSpec = lookupValue
  };

  updateEnterpriseProfile = (lookupValue: LibItem) => {
    this.enterpriseProfile = lookupValue
  };

  updateTimeZone = (lookupValue: LibItem) => {
    this.timeZone = lookupValue;
  };
  
  updateAudios = (audioValues: IAudioAuthorization[]) => {
    this.audios = audioValues;
  };

  asMetadata = () => {
    this.type = AuthorizationType.Metadata;
    this.virtualRecord = true;
    return this;
  }
}

export class LibItem {
  name: string = '';
  value: string = '';
  description: string = '';
  tabId: number | null = null;
  isSelected: boolean = false;
}

export class AuthKey {
  deliverableId: string = '';
  sequenceId: number = 1
  type: AuthorizationType;
  constructor(id: string, sequence: number, type: AuthorizationType)
  {
    this.deliverableId = id;
    this.sequenceId = sequence;
    this.type = type;
  }
}

export interface IAuthMediaIdCollection {
  caption: string;
  isTempHoldback: boolean;
  mediaIds: string[] | null;
  applicationIds: number[];
  applicationAssetIds: number[];
  isInPreviewAuthMode: boolean;
}

export class AuthMediaIdCollection {
  caption: string = '';
  isTempHoldback: boolean = false;
  mediaIds: string[] | null = null;
  applicationIds: number[] = [];
  applicationAssetIds: number[] = [];
  isInPreviewAuthMode: boolean = false;
}
