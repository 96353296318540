import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SettingsManager } from "../utils/settingsManager";
import { AxiosResponse } from "axios";
import { LocalStorageHelper } from "../utils/localStorage-helper";
import { Notifications } from "../../features/notifications/Notifications";
import agent from "../api/agent";
import { LoadingIndicator } from "../layout/LoadingIndicator";

function useQuery() {
  return new URLSearchParams(useLocation().hash.substring(1));
}

const Login = () => {
  const query = useQuery();
  const token = query.get("access_token");
  const history = useHistory();
  const settingsManager = new SettingsManager();
	const notifications = new Notifications();

  LocalStorageHelper.clearAuthToken();

  useEffect(() => {
    const defaultMsg = `There was a problem authenticating`;
    if (!token) {
      window.location.href = settingsManager.getAuthProviderUrl();
    } else {
      window.history.replaceState({}, document.title, `/${SettingsManager.CALLBACK_PATH}`);
  
      agent.Auth.authenticate(token).then(res => {
        if (res.token) {
          LocalStorageHelper.setAuthToken(res.token);
          history.push("/preauth");
          return
        }
        console.log(res);
        notifications.error(defaultMsg); 
      }).catch((err: AxiosResponse) => {
        console.log(err);
  
        switch (err?.status) {
          case 400:
          case 401:
            notifications.error(err?.data || defaultMsg);           
            break;
          case 404:
            notifications.error("User not found!");
            break;
          default:
            notifications.error(defaultMsg); 
            break;
        }
      });    
    }
  },[]) 

  return (
    <LoadingIndicator />
  )
}

export default Login;
