import React, { useContext } from 'react';
import {
    GridColumnMenuFilter,
    GridColumnMenuCheckboxFilter
} from '@progress/kendo-react-grid';
import { RootStoreContext } from '../../app/stores/rootStore';




export const ColumnMenu = (props:any) => {
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true}/>
        </div>
    );
}

export const TitleVersionColumnMenuCheckboxFilter = (props:any) => {      

    const rootStore = useContext(RootStoreContext);
    const {
        titleVersions,       
      } = rootStore.workspaceStore;
    
      return (
          <div>
              <GridColumnMenuCheckboxFilter {...props} data={Array.from(titleVersions.values())} expanded={true}/>
          </div>
      );
}

