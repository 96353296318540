import { IdName } from "./idName";

export enum FieldType {
  Textbox = 1,
  Dropdown = 2,
  Multiselect = 3, // -> delete if does not break
  Audio = 4,
  Date = 8,
  Preset = 16,
  DateTime = 32
}

export interface IField {
  id: number;
  name: string;
  binding?: string;
  fieldType?: FieldType;
  isRequired: boolean;  
  type?: IdName;
}

export class Field implements IField {
  id: number;
  name: string;
  isRequired: boolean;
  binding?: string | undefined;
  fieldType?: FieldType | undefined;
  

  constructor(id: number, name: string, fieldType: FieldType, isRequired: boolean, binding?: string) {
    this.id = id;
    this.name = name;
    this.fieldType = fieldType;
    this.binding = binding;
    this.isRequired = isRequired;    
  
  }
}