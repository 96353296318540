import { LocalStorageHelper } from './../utils/localStorage-helper';
import { ITabsRequest } from "./../models/tabsRequest";
import { ILookupValuesResponse } from "./../models/lookupValuesResponse";
import { WorkspaceTitleVersions } from "./../models/workspace";
import axios, { AxiosResponse, CancelToken, CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { IAuthorization } from "../models/authorization";
import { IGroup } from "../models/group";
import { ITitle } from "../models/title";
import { IWorkspace } from "../models/workspace";
import { AuthorizationRequest, PreviewAuthorizationRequest } from "../models/authorizationRequest";
import { IAsset } from "../models/asset";
import { SettingsManager } from "../utils/settingsManager";
import { IAuthorizationTab } from "../models/authorizationTab";
import { IPreset } from '../models/preset';

const settingsManager = new SettingsManager();

axios.defaults.baseURL = settingsManager.settings.apiBaseUrl;

const responseBody = (response: AxiosResponse) => response?.data;

axios.interceptors.request.use(
  config => {
    const accessToken = LocalStorageHelper.getAuthToken();    
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  error => {    
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(undefined, (error) => {
  if (axios.isCancel(error)) {    
    return Promise.reject(error);
  }

  if (error.response)
  {
    const { status, config } = error.response;

    if (status === 401) {
      history.push('/login');    
    }
  
    if (status === 500) {
      toast.error("Server error - check the API logs for more details!");
    }
  } else {
    //Unable to hit API
    toast.error("Error connecting with the Server");
  }

  return Promise.reject(error);
});

//Use this to fake a delay in api calls...
//ex: axios.get(url).then(sleep(1000)).then(responseBody)
// const sleep = (ms: number) => (response: AxiosResponse) => 
//     new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));

const requests = {
  get: (url: string, cancelToken?: CancelToken) => {
    if (cancelToken === undefined) {
      return axios.get(url).then(responseBody);
    } else {
      return axios.get(url, { cancelToken: cancelToken }).then(responseBody);
    }
  },
  genPost: (url: string, baseURL: string, body: {}, headers: {}, cancelToken?: CancelToken) => {
    if (cancelToken === undefined) {
      return axios.post(url, body, {baseURL, headers}).then(resp => {
      });
    } else {
      return axios
        .post(url, body, { cancelToken: cancelToken })
        .then(responseBody);
    }
  },
  post: (url: string, body: {}, cancelToken?: CancelToken) => {
    if (cancelToken === undefined) {
      return axios.post(url, body).then(responseBody);
    } else {
      return axios
        .post(url, body, { cancelToken: cancelToken })
        .then(responseBody);
    }
  },
  put: (url: string, body: {}, cancelToken?: CancelToken) => {
    if (cancelToken === undefined) {
      return axios.put(url, body).then(responseBody);
    } else {
      return axios
        .put(url, body, { cancelToken: cancelToken })
        .then(responseBody);
    }
  },
  del: (url: string, cancelToken?: CancelToken) => {
    if (cancelToken === undefined) {
      return axios.delete(url).then(responseBody);
    } else {
      return axios.delete(url, { cancelToken: cancelToken }).then(responseBody);
    }
  },
};

let searchGroupCall: CancelTokenSource;

const Groups = {
  search: (keyword: string): Promise<IGroup[]> => {
    if (searchGroupCall) {
      searchGroupCall.cancel();
    }
    searchGroupCall = axios.CancelToken.source();
    return requests.get(`/groups?keyword=${keyword}`, searchGroupCall.token);
  },
};

let titleSuggestCall: CancelTokenSource;

const Titles = {
  suggest: (keyword: string, hierarchyId: number): Promise<ITitle[]> => {
    if (titleSuggestCall) {
      titleSuggestCall.cancel();
    }
    titleSuggestCall = axios.CancelToken.source();
    return requests.get(
      `/titles/typeahead?keyword=${keyword}&hierarchyid=${hierarchyId}`,
      titleSuggestCall.token
    );
  },
};

let titleVersionSearchCall: CancelTokenSource;

const TitleVersions = {
  searchById: (
    ids: number[],
    hierarchyId: number
  ): Promise<WorkspaceTitleVersions> => {
    if (titleVersionSearchCall) {
      titleVersionSearchCall.cancel();
    }
    titleVersionSearchCall = axios.CancelToken.source();
    return requests.post(
      "/titles/versions",
      {
        hierarchyId: hierarchyId,
        versionIds: ids.join(","),
      },
      titleVersionSearchCall.token
    );
  },
  searchByWprId: (
    wprids: string[],
    keyword: string,
    hierarchyId: number
  ): Promise<WorkspaceTitleVersions> => {
    if (titleVersionSearchCall) {
      titleVersionSearchCall.cancel();
    }
    titleVersionSearchCall = axios.CancelToken.source();
    return requests.post(
      "/titles/versions",
      {
        hierarchyId: hierarchyId,
        wprIds: wprids.join(","),
        keyword: keyword
      },
      titleVersionSearchCall.token
    );
  },
  searchByDeliverableId: (
    deliverableIds: string[],
    hierarchyId: number
  ): Promise<WorkspaceTitleVersions> => {
    if (titleVersionSearchCall) {
      titleVersionSearchCall.cancel();
    }
    titleVersionSearchCall = axios.CancelToken.source();
    return requests.post(
      "/titles/versions",
      { 
        hierarchyId,
        deliverableIds: deliverableIds.join(",")
      },
      titleVersionSearchCall.token
    );
  }
};

const Workspaces = {
  details: (id: string): Promise<IWorkspace> => 
    requests.get(`/workspaces/${id}`),
  create: (workspace: IWorkspace): Promise<string> =>
    requests.post("/workspaces", workspace),
};

let authorizationDetailsCall: CancelTokenSource;
let authorizationUpdateCall: CancelTokenSource;
let authorizationPreviewCall: CancelTokenSource;

const Authorizations = {
  details: (uniqueId: string, tabId: number): Promise<IAuthorization[]> => {
    if (authorizationDetailsCall) {
      authorizationDetailsCall.cancel();
    }
    authorizationDetailsCall = axios.CancelToken.source();
    return requests.get(
      `/auths/${uniqueId}/${tabId}`,
      authorizationDetailsCall.token
    );
  },
  update: (authRequest: AuthorizationRequest) => {
    if (authorizationUpdateCall) {
      authorizationUpdateCall.cancel();
    }
    authorizationUpdateCall = axios.CancelToken.source();
    return requests.post(
      `/auths/${authRequest.uniqueId}/${authRequest.tabId}`, 
      { 
        auths: authRequest.auths ?? [],
        deletedItems: authRequest.deletedItems ?? []
      }, 
      authorizationUpdateCall.token);
  },
  preview: (previewAuthRequest: PreviewAuthorizationRequest): Promise<IAuthorization[]> => {
    if(authorizationPreviewCall){
      authorizationPreviewCall.cancel();
    }
    authorizationPreviewCall = axios.CancelToken.source();

    return requests.post(
      `/auths/${previewAuthRequest.uniqueId}/${previewAuthRequest.tabId}/preview`,
      {
        auths: previewAuthRequest.auths ?? []
      },
      authorizationPreviewCall.token
    );
  }
};

const Assets = {
  list: (deliverableId: string, sequenceId: number, authId: number): Promise<IAsset[]> => {
    let url = `/assets?id=${authId}`;
    if(deliverableId)
      url = `/assets?sequenceid=${sequenceId}&deliverableid=${deliverableId}`;
    return requests.get(url);
  },
  search: (applicationAssetIds: number[]): Promise<IAsset[]> => {
    let url = `/assets/search`;
    return requests.post(url, applicationAssetIds ?? []);
  }
}

const Tabs = {
  list: (): Promise<ITabsRequest> => requests.get("/tabs"),
  fulfillmentStatuses: (workspaceUniqueId: string): Promise<IAuthorizationTab[]> => requests.get(`/tabs/fulfillment/${workspaceUniqueId}`)
};

const Lookups = {
  list: (): Promise<ILookupValuesResponse[]> => requests.get("/lookup"),
}

const presets = {
  details: (id: number): Promise<IPreset> => requests.get(`/preset/${id}`),
}

const Auth = {
  authenticate: (token: string): Promise<{ user: any, token: string }> =>  {
    const authRequest = axios.create({
      baseURL: settingsManager.settings.authBaseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
        ["Ocp-Apim-Subscription-Key"]: settingsManager.settings.apimKey
      }
    })
    return authRequest.get(SettingsManager.AUTH_PLATFORM_PATH).then(responseBody);
  },
  validate: (): Promise<void> => requests.get("/auth/validate")
}

export default {
  Authorizations,
  Assets,
  Groups,
  Tabs,
  Titles,
  TitleVersions,
  Workspaces,
  Lookups,
  presets,
  Auth
};
