
import { LibItem } from '../models/authorization';
import { FieldType, IField } from '../models/field';
import { RootStore } from './rootStore';
import { action, observable } from 'mobx';
import { MetadataFieldValue } from '../models/metadataFieldValue';

export default class AuthorizationFormStore {
  RootStore: RootStore;
  constructor(rootstore: RootStore) {
    this.RootStore = rootstore
  }

  @observable fields: IField[] = [
    { 
      id: 1040, 
      name: "Start Date", 
      binding: "startDate", 
      fieldType: FieldType.DateTime, 
      isRequired: false
    },
    { 
      id: 1041, 
      name: "End Date", 
      binding: "endDate", 
      fieldType: FieldType.DateTime, 
      isRequired: false
    },
    { 
      id: 1060, 
      name: "Time Zone", 
      binding: "timeZone", 
      fieldType: FieldType.Dropdown, 
      isRequired: false
    }
  ];
  
  @observable authFieldValues = new Map<number, MetadataFieldValue>();
  @observable isTimeZoneDropDownOpen: boolean = false;

  @action updateFieldValue = (field: IField, value?: string | LibItem | LibItem[] | any[] | null) => {
    try {
      if (!value) {
        this.authFieldValues.delete(field.id);
      } else {
        this.authFieldValues.set(field.id, new MetadataFieldValue(field, value))
      }
    } catch (error) {
      console.error(error);
    }
  }
 
  @action clearFieldValues = () => {
    this.authFieldValues.clear();
  }

  @action openTimeZoneDropDown = () => {
    this.isTimeZoneDropDownOpen = true;
  }

  @action closeTimeZoneDropDown = () => {
    this.isTimeZoneDropDownOpen = false;
  }  
}