import { AuthFieldTypes } from '../enums/authFieldTypes';
import { MetadataFieldValue } from '../models/metadataFieldValue';
import { IValidator } from './IValidator';

export class VideoMetadataValidator implements IValidator<MetadataFieldValue> {
  messages: string[];
  items: MetadataFieldValue[];

  constructor(items: MetadataFieldValue[]) {
    this.items = items;
    this.messages = [];
  }

  validate = () => {
    let result = true;
    const audioValues = this.items.find(x => x.field.id === AuthFieldTypes.AUDIO);
    
    if (!audioValues) {
      result = false;
    } 

    (audioValues?.audioValues ?? []).forEach(x => {
      if (!x.language) {
        result = false;
      }
    })

    if (!result) {
      let message = "This Authorization does not have an Audio Language value assigned. "
      message += "Click OK to continue to Add it without an audio Language assigned." 
      this.messages.push(message);
    }

    return result;
  }
}