import React from 'react'
import { 
  makeStyles, 
  Theme, Tabs, Tab, Box, AppBar
} from '@material-ui/core';

import { ITab } from "../../app/ui-models/tab";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    key: index
  };
}

const tabStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "inherit !important",
    boxShadow: "none !important",
    borderBottom: "0.2px solid lightgray"
  },
  success: {
    color: "#1C826B"
  },
  warning: {
    color: "#F78965"
  },
  default: {
    color: "#000"
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const TabsBuilder = (props: any) => {

  const tabsData: ITab[] = props.tabData;
  const classes = tabStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const tabs = tabsData.map((tab, i) => {
    let classname = tab.className ? tab.className : "default";
    // @ts-ignore
    return <Tab label={tab.label} className={classes[classname]} {...a11yProps(i)} />
  });

  const tabPanels = tabsData.map((tab, i) => {
    return <TabPanel value={value} key={i} index={i}> {tab.component} </TabPanel>
  })

  return(
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs value={value} variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="simple tabs example">
          {tabs}
        </Tabs>
      </AppBar>
      {tabPanels}
    </div>
  );

}

export default TabsBuilder;