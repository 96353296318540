import { action, observable } from "mobx";
import { IUser } from "../models/user";
import { RootStore } from "./rootStore";

export default class UserStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
      this.rootStore = rootStore;
    }

    @observable loggedInUser: IUser | null = null;
    @observable accessToken: string = '';
    
    @action setAccessToken = async (token: string) => {
      this.accessToken = token;
    }

    @action setLoggedInUser = async (user: IUser | null) => {
      this.loggedInUser = user;
    }
}