import React, { useContext } from 'react';
import {Grid as KendoGrid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridHeaderCellProps, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Button, Checkbox, Chip, Grid, LinearProgress, makeStyles, Theme, Tooltip } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import {TitleVersionColumnMenuCheckboxFilter } from './columnMenu';
import { State } from '@progress/kendo-data-query';

const gridStyles2 = makeStyles((theme: Theme) => ({
 titleversions: {
    height: "400px",
    marginTop: 0,      
    '& .k-master-row': {
      fontSize: "12px",
      '& td': {
        minHeight: 43,
        padding: '3px 24px'
      }
    }    
  }
  }));

const cellStyles = makeStyles((theme: Theme) => ({
  titleTd: {
    display: "flex", 
    flexDirection: "column"
  },
  titleIds: {
    marginTop: -10,
    color: "gray",
    opacity: 0.7
  },
  chip: {
    width: "100px",
    height: "15px",
    borderRadius: "5px",
    fontSize: "11px"
  },
  redChip: {
    backgroundColor: "#C5283D"
  },
  row: {
    fontSize: "12px",
    '& td': {
      minHeight: 43,
      padding: "3px 24px"
    }
  },
  selectedRow: {
    backgroundColor: "rgba(0, 0, 0, 0.07)"
  }
}));


const initialDataState: State = {
  sort: []
};


const TitleVersionsGrid = () => {

  const classes = cellStyles();
  const gridClass = gridStyles2();
  const rootStore = useContext(RootStoreContext);

  const {    
    filteredTitleVersions: titleVersions,
    isLoadingTitleVersions,
    filterTitleVersionsData,
    removeTitleVersions,
    removeAllTitleVersions,
    titleVersionFilters,
    toggleTitleVersionSelection,
    toggleAllTitleVersionSelection,
    allTitleVersionsSelected
  } = rootStore.workspaceStore;

  const [foo, setFoo] = React.useState(false);
  const [gridDataState, setGridDataState] = React.useState(initialDataState);
  


  // if (isLoadingTitleVersions) {
  //   return (
  //   <React.Fragment>
  //       <Grid container spacing={3} style={{ height: '400px', paddingTop: "15%" }} justify="flex-end">
  //         <Grid item>
  //           <CircularProgress color="inherit" />
  //         </Grid>
  //       </Grid>
  //     </React.Fragment>
  //   );
  // }


  const dataStateChange = (event: GridDataStateChangeEvent) => {    
    filterTitleVersionsData(event.data);        
    setFoo(!foo);
  }

  const selectionChange = (event: GridSelectionChangeEvent) => {    
    toggleTitleVersionSelection(event.dataItem.id);    
    setFoo(!foo);
  }

  const toggleItemSelection = (id: number) => {
    toggleTitleVersionSelection(id);    
    setFoo(!foo);
  }

  const headerSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;
    toggleAllTitleVersionSelection(checked);
    setFoo(!foo);
  }

  const removeSelectedItems = () => {
    removeTitleVersions();
    setFoo(!foo);
  }


  //#region Custom Grid Cell/Column
  
  const checkBoxHeaderCell = (props: React.PropsWithChildren<GridHeaderCellProps>) => {
  return (
    <Checkbox
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
        checked={allTitleVersionsSelected}
        onClick={props.onClick}
      />
  )
  }

  const checkBoxCell = (props: React.PropsWithChildren<GridCellProps>) => {
    return (
      <td>
        <Checkbox
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          checked={(props.dataItem.selected) }
          onClick={() => toggleItemSelection(props.dataItem.id)}
        />
      </td>
    )
  }

  const releaseDateCell = (props: React.PropsWithChildren<GridCellProps>) => {
    return (
      <td>
        { props.dataItem.title.releaseDate ? moment(props.dataItem.title.releaseDate).format("DD MMM YYYY") : "" }
      </td>
    )
  }

  const titleNameCell = (props: React.PropsWithChildren<GridCellProps>) => {
    const productId = props.dataItem["title"]["cpmProductId"];
    const wrpId = props.dataItem["title"]["wprId"];
    const isHholdback = Boolean(props.dataItem["title"]["isHoldback"] ?? false);

    return (
      <td className={classes.titleTd}>
        <span>{props.dataItem.title.name}</span>
        <small className={classes.titleIds}>
          {productId} - ({wrpId})
        </small>
        {isHholdback ? <Chip label="Holdback" color="secondary" className={`${classes.chip} ${classes.redChip}`} /> : ""}
      </td>
    );
  }

  const episodeNameHeaderCell = (props: React.PropsWithChildren<GridHeaderCellProps>) => {
    return (
      <a className="k-link" onClick={props.onClick}>
        <span style={{display:'block', lineHeight:'12px'}}>Title Name</span>        
        <small>Product ID - (Title ID)</small>
        {props.children}
      </a>
    )
  }

  //#endregion

 
  return (
    <React.Fragment>
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
            <Tooltip title="Remove selected items">
              <Button size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }}  onClick={() => removeSelectedItems()}>
                <DeleteOutlineIcon color="primary" />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
          <Tooltip title="Remove all items">
            <Button size="small" variant="outlined" color="primary" style={{ minWidth: "auto" }} onClick={() => removeAllTitleVersions()}>
              <DeleteSweepOutlinedIcon color="primary" />
            </Button>
            </Tooltip>
        </Grid>
        <Grid item xs={12}>
        {isLoadingTitleVersions &&                              
              <LinearProgress />                                  
        }
        </Grid>        
        <Grid item xs={12}>        
          <KendoGrid
            data={titleVersions}
            {...titleVersionFilters}
            className={gridClass.titleversions}
            selectedField="selected"
            onDataStateChange={dataStateChange}
            onSelectionChange={selectionChange}
            onHeaderSelectionChange={headerSelectionChange}
            sortable= {true}
            >
            <Column field="selected"             
              width="65px" 
              />
            <Column field="title.sequenceNumber" title="Ep #" width="40px" />
            <Column field="title.name" title="Episode Name" 
              cell={titleNameCell} 
              headerCell={episodeNameHeaderCell}               
              />
            <Column field="description" title="Version Description" columnMenu={TitleVersionColumnMenuCheckboxFilter}/>
            <Column field="vaultVersionId" title="Version ID"  width="150px"/>
            <Column field="title.productType" title="Product Type"  width="180px"/>
            <Column field="title.releaseDate" title="Release Date" cell={releaseDateCell} width="150px"/>
          </KendoGrid>
          </Grid>
      </Grid>
      
            
    </React.Fragment>
  )
}

export default observer(TitleVersionsGrid)
