import { Chip, CircularProgress, Dialog, DialogTitle, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Grid as KendoGrid, GridColumn as Column, GridHeaderCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
  chip: {
    width: "100px",
    height: "22px",
    borderRadius: "5px",
  },
  redChip: {
    backgroundColor: "#C5283D"
  },
  orangeChip: {
    backgroundColor: "orange",
    width: "140px"
  },
  grid: {
    height: 400,
    margin: 20, 
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)"
  },
  row: {
    fontSize: "12px",
    '& td': {
      padding: "3px 24px"
    }
  },
  columnDisplay: {
    display: "flex !important", 
    flexDirection: "column"
  }
}));

const AssetsDialog: React.FC = () => {
  const classes = useStyles();
  const context = useContext(RootStoreContext)
  const {
    openAssetsDialog,
    isOpenedAssetsDialog,
    loadAssets,
    isLoadingAssets,
    assets,
    clearAssets,
    deliverableId,
    sequenceId,
    authId,
    applicationAssetIds
  } = context.authorizationStore

  React.useEffect(() => {
    if (isOpenedAssetsDialog) {
      loadAssets(deliverableId as string, sequenceId as number, authId as number, applicationAssetIds as number[]);
    }

    return function cleanUp() {
      clearAssets();
    }

  }, [isOpenedAssetsDialog, deliverableId, sequenceId, authId, applicationAssetIds])

  const handleClose = () => {
    openAssetsDialog(false);
  };

  const buildDeliveryTypeCell = (props: any) => {
    const data = `${props.dataItem["deliveryType"]} / ${props.dataItem["versionId"]} / ${props.dataItem["versionDescription"]}`;
    return <td>{data}</td>;
  };

  const buildAudioCell = (props: any) => {
    const data: string[] = (props.dataItem["audio"] ?? "").split("|");
    return (
      <td>
        <div className={classes.columnDisplay}>
          {data.map(x => <span>{x}</span>)}
        </div>
      </td>
    );
  };

  const buildLanguagesCell = (props: any) => {
    const data = `${props.dataItem["subtitleLanguage"] ?? ""} / ${props.dataItem["textedLanguage"] ?? ""} / ${props.dataItem["conformedLanguage"] ?? ""}`;
    return <td>{data}</td>;
  };

  const buildAssetNameCell = (props: any) => {
    const isHoldback = Boolean(props.dataItem["isHoldback"] ?? false);
    const isReplacement = Boolean(props.dataItem["isReplacement"] ?? false);
    const replacementLabel = Boolean(props.dataItem["isMandatoryReplacement"] ?? false) ? "Mandatory Replacement" : "Replacement";

    return (
      <td>
        <span>{props.dataItem["assetName"]}</span>
        {isHoldback ? <Chip label="Holdback" color="secondary" className={`${classes.chip} ${classes.redChip}`} /> : ""}
        {isReplacement ? <Chip label={replacementLabel} color="secondary" className={`${classes.chip} ${classes.orangeChip}`} /> : ""}
      </td>);
  };

  const buildAssetGroupHeader = (props: React.FunctionComponent<GridHeaderCellProps> | any) => {
    return (
      <a className={`k-link ${classes.columnDisplay}`} onClick={props.onClick}>
        <span style={{ marginBottom: -8 }}>Asset</span>
        <span>Group</span>
        {props.children}
      </a>
    )
  }

  const buildVersionTypeHeader = (props: React.FunctionComponent<GridHeaderCellProps> | any) => {
    return (
      <a className={`k-link ${classes.columnDisplay}`} onClick={props.onClick}>
        <span style={{ marginBottom: -8 }}>Delivery Type / Version ID</span>
        <span>Version Description</span>
        {props.children}
      </a>
    )
  }

  const buildSubtitleTextHeader = (props: React.FunctionComponent<GridHeaderCellProps> | any) => {
    return (
      <a className={`k-link ${classes.columnDisplay}`} onClick={props.onClick}>
        <span style={{ marginBottom: -8 }}>Subtitle/Texted/</span>
        <span>Conformed</span>
        {props.children}
      </a>
    )
  }


  const handleRowRender = (row: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    return (
      <tr 
        className={classes.row}>
        {row.props.children}
      </tr>
    );
  }

  const buildDialogContent = () => {
    if (isLoadingAssets) {
      return(
        <React.Fragment>
          <Grid container spacing={3} style={{ height: '400px', paddingTop: "15%" }} justify="center">
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>

        <hr style={{ width: "99%", color: "lightgray", opacity: 0.2 }} />
        <KendoGrid className={classes.grid}
          data={assets}
          rowRender={handleRowRender}
        >
          <Column field="mediaId" title="Media ID(s)" width="120px" />
          <Column title="Asset Name / Filename" width="250px" cell={buildAssetNameCell}/>
          <Column field="assetGroup" title="Asset Group" headerCell={buildAssetGroupHeader} width="120px"/>
          <Column field="techSpec" title="Tech Spec"  width="150px"/>
          <Column title="Delivery Type / Version ID Version Description"
            width="150px"
            cell={buildDeliveryTypeCell}
            headerCell={buildVersionTypeHeader}
          />
          <Column field="audio" title="Audio" width="100px" cell={buildAudioCell}/>
          <Column title="Subtitle/Texted/Conformed" width="150px" 
            cell={buildLanguagesCell} 
            headerCell={buildSubtitleTextHeader}
          />
          <Column field="assetId" title="Alternate ID" width="120px"/>
        </KendoGrid>
      </React.Fragment>
    )
  }


  return(
    <Dialog open={isOpenedAssetsDialog} maxWidth="lg" fullWidth={true} onClose={() => handleClose()} aria-labelledby="assets-dialog-title">
      <Grid container justify="space-between">
        <Grid item>
          <DialogTitle id="assets-dialog-title" style={{ marginBottom: -20 }}>
            Assets Authorized
          </DialogTitle>
        </Grid>
        <Grid item>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => {handleClose()}}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {buildDialogContent()}
    </Dialog>
  );
}

export default observer(AssetsDialog);