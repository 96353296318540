import React, { useContext } from 'react';
import { 
  Grid, InputLabel, 
  MenuItem, Select, TextField 
} from '@material-ui/core';
import GroupFieldSearch from './GroupFieldSearch';
import { IGroup } from '../../app/models/group';
import { IBaseTitle } from '../../app/models/basetitle';
import { HierarchyType } from '../../app/models/lookupValues';
import TitleFieldSearch from './TitleFieldSearch';
import { RootStoreContext } from '../../app/stores/rootStore';

interface IProps {
  groupProps: {
    group?: IGroup,
    searchGroup: (keyword: string) => Promise<void>,
    groupSuggestions: IGroup[],
    groupCallback: React.Dispatch<React.SetStateAction<IGroup | null>>,
    isLoadingGroups: boolean
  },
  titleProps: {
    searchTitles: (keyword: string, hierarchyId: number) => Promise<void>,
    titleSuggestions: IBaseTitle[],
    searchTitleVersionsByWprId: (wprids: string[], keyword: string, hierarchyId: number) => Promise<void>,
    isLoadingTitles: boolean
  },
  hierarchyProps: {
    hierarchyId: number,
    hierarchyTypes: HierarchyType[],
    setHierarchyId: React.Dispatch<React.SetStateAction<number>>
  }
}

const GroupTitleFields: React.FC<IProps> = ({
  groupProps,
  titleProps,
  hierarchyProps
}) => {

  const rootStore = useContext(RootStoreContext);

  const {        
    searchTitleVersionsByIds,
    tabChange,
    devIdworkspace
  } = rootStore.workspaceStore;

  const [versionIds, setVersionIds] = React.useState("");

  React.useEffect(() => {
    if (devIdworkspace) {
      tabChange();
    }
  },[devIdworkspace, tabChange])

  const handleHierarchy = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value ? +(event.target.value as string) : 0;
    hierarchyProps.setHierarchyId(value);
  };
  
  const handleKeyDownOnVersionsId = (event: any) => {
    let ids: number[] = [];
    const value = event.target.value as string;
    if (event.key === 'Enter') {
      event.preventDefault();
      setVersionIds(value);
      if (value.trim().length > 0) {
        ids = value.split(",").map(x => +x);
        searchTitleVersionsByIds(ids, hierarchyProps.hierarchyId);
      }
    }
  }

  const handlePasteVersionIds = (event: any) => {
    event.persist();
    event.stopPropagation();
    event.preventDefault();
    const clipboardData = event.clipboardData;
    let data = clipboardData.getData("Text");
    if (data.length > 0 && !data.includes(",")) {
      data = data.split(/\r?\n|\r/g).join(",")
    }
    
    event.target.value = data;
    setVersionIds(data);
  }

  return(
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <GroupFieldSearch 
            group={groupProps.group} 
            groupSuggestions={groupProps.groupSuggestions} 
            searchGroup={groupProps.searchGroup}
            groupCallback={groupProps.groupCallback}
            isLoadingGroups={groupProps.isLoadingGroups}
          />
        </Grid>

        <Grid item xs={7}>
          <TitleFieldSearch 
            titleSuggestions={titleProps.titleSuggestions} 
            searchTitles={titleProps.searchTitles}
            searchTitleVersionsByWprId={titleProps.searchTitleVersionsByWprId}
            isLoadingTitles={titleProps.isLoadingTitles}
            hierarchyId={hierarchyProps.hierarchyId}
          />
        </Grid>
       

      </Grid>

      <Grid container spacing={3} style={{ marginTop: "12px" }}>
        <Grid item xs={5} style={{ paddingTop: 18.5 }}>
          <InputLabel id="hierarchy-label">Hierarchy</InputLabel>
          <Select
            labelId="hierarchy-label"
            id="hierarchy"
            value={hierarchyProps.hierarchyId}
            onChange={handleHierarchy}
            style={{ minWidth: "100%" }}
          >
            {hierarchyProps.hierarchyTypes.map(x => {
              return <MenuItem key={`hierarchy_${x.id}`} value={x.id}>{x.name}</MenuItem>
            })}
          </Select>
        </Grid>
        {/* <Grid item xs>
          <TextField
            autoFocus
            margin="dense"
            id="contract"
            label="Contract - Deal ID"
            type="search"
            fullWidth
          />
        </Grid> */}
        <Grid item xs={7}>
          <TextField
            autoFocus
            margin="dense"
            id="version"
            label="Version ID(s)"
            multiline
            fullWidth
            onKeyDown={handleKeyDownOnVersionsId}
            onPaste={handlePasteVersionIds}
            defaultValue={versionIds}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>

      </Grid>
    </React.Fragment>
  )
}

export default GroupTitleFields;