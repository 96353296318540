import { ITitle } from "./title";

export interface ITitleVersion {
    id: number;
    vaultVersionId?: number;
    title?: ITitle;
    type?: string;
    description?: string;
}

export class TitleVersion implements ITitleVersion {
    id: number = 0;
    vaultVersionId?: number = undefined;
    title?: ITitle = undefined;
    type?: string = '';
    description?: string = '';
    selected: boolean = false;

    constructor(value: ITitleVersion)
    {
        Object.assign(this, value);   
        if (this.title && this.title.releaseDate) {
            this.title.releaseDate = new Date(this.title.releaseDate);            
        }
    }
}
