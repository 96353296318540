import { IdName } from './idName';
import { IField } from "./field";

export enum TabStatus {
  NoRecords = 1,
  NotFulfilled = 2,
  Fulfilled = 4,
}

class TabStatusColor {

  private NoRecordsColor = "#000";
  private NotFulfilledColor = "#F78965";
  private FulfilledColor = "#1C826B";

  checkAndGetStatusColor = (statusId: number) => {
    let color = this.NoRecordsColor;
    switch (statusId) {
      case TabStatus.Fulfilled:
        color = this.FulfilledColor;
        break;
      case TabStatus.NotFulfilled:
        color = this.NotFulfilledColor;
        break;
      default:
        break;
    }

    return color;
  }
}

export const tabStatusColor = new TabStatusColor();

export enum PriorityType {
  Primary_Metadata = -1,
  Secondary_Metadata = 1,
  Additional_Metadata = 2
}

export interface IAuthorizationTab {
  id: number;
  name: string;
  fields: ITabField[];
  authorizationsCount: number;
  downloadedAuthorizationsCount: number;
  status: IdName;
}

export interface ITabField extends Partial<IField> {
  tabId: number;
  priority: PriorityType;
  isRequired: boolean;
  type: IField
}