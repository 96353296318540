import React, { useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  InputAdornment, 
  TextField 
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SearchOutlined } from '@material-ui/icons';

import { IGroup } from '../../app/models/group';
import { IDistributionGroup } from '../../app/models/distributionGroup';
import { observer } from "mobx-react-lite";
import { RootStoreContext } from '../../app/stores/rootStore';

interface IProps {
  group?: IGroup;
  searchGroup: (keyword: string) => Promise<void>,
  groupSuggestions: IGroup[],
  groupCallback: React.Dispatch<React.SetStateAction<IGroup | null>>,
  isLoadingGroups: boolean
}

const GroupFieldSearch: React.FC<IProps> = ({
  group: initialGroup,
  groupSuggestions,
  searchGroup,
  groupCallback,
  isLoadingGroups
}) => {
  const [textValue, setTextValue] = React.useState('');
  const rootStore = useContext(RootStoreContext);
  const {        
      addInitialGroupOption
  } = rootStore.workspaceStore;

  const prepareDistributionDisplay = (distribution: IDistributionGroup[]) => {
    return distribution.map(x => x.abbr).join(",");
  }

  const prepareGroupDisplay = (group: IGroup) => {
    if (group.apps && group.customerType !== undefined && group.customerType !== null) {
        return `${group.id} ${group.name} - (${prepareDistributionDisplay(group.apps)}) - ${group.customerType}`;
    } else if (group.customerType !== undefined && group.customerType !== null) {
        return `${group.id} ${group.name} - ${group.customerType}`;
    } else if (group.apps) {
        return `${group.id} ${group.name} - (${prepareDistributionDisplay(group.apps)})`;
    } else {
      return `${group.id} ${group.name}`;
    }
  }

  React.useEffect(() => {
    if (initialGroup) {
      setTextValue(prepareGroupDisplay(initialGroup));
      addInitialGroupOption(initialGroup as IGroup);
    }
  }, [initialGroup, addInitialGroupOption, prepareGroupDisplay])

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string, reason: string) => {
    if (reason === "input") {
      setTextValue(value);
      if (value.length > 0) {
        searchGroup(value);
      }
    } else if (reason === "clear") {
      setTextValue("");
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, value: IGroup | null) => {
    if (value) {
      setTextValue(prepareGroupDisplay(value as IGroup));
    }
    groupCallback(value as IGroup);
  }


  
  return (
    <Autocomplete
      id="customer"
      fullWidth
      inputValue={textValue}
      value={(initialGroup ?? {}) as IGroup}
      noOptionsText='No results'
      onChange={handleChange}
      onInputChange={handleInputChange}
      // getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => prepareGroupDisplay(option)}
      options={groupSuggestions}
      loading={isLoadingGroups}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Customer/Vendor/Group No."
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {isLoadingGroups ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default observer(GroupFieldSearch);