import { 
  Button, Card, CardContent,
  Dialog, DialogContent, DialogContentText,
  DialogTitle, Grid, TextField, Tooltip
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { LibItem } from '../../../app/models/authorization';
import { IField, FieldType } from '../../../app/models/field';
import { RootStoreContext } from '../../../app/stores/rootStore';
import moment from 'moment';

interface IProps {
  tabId: number;
}

const AuthorizationForm: React.FC<IProps> = ({tabId}) => 
{

  const rootStore = useContext(RootStoreContext);

  const {  
    lookupValuesRegistry,
    loadLookupValues,
    loadLookups    
  } = rootStore.metadatastore

  const {
    tabs,
    isOpenedEditDialog,    
    openEditDialog,    
    updateAuthorizations    
  } = rootStore.authorizationStore

  const {
    fields,
    isTimeZoneDropDownOpen,
    authFieldValues,
    clearFieldValues,
    updateFieldValue,
    openTimeZoneDropDown,
    closeTimeZoneDropDown
  } = rootStore.authorizationFormStore

  const tab = tabs.get(tabId);

  React.useEffect(() => {    
    if (lookupValuesRegistry.size === 0) {
      loadLookupValues();
    }
  }, [lookupValuesRegistry, loadLookupValues])

  const useStyles = makeStyles((theme) => ({
    autocompleteContainer: {
      marginTop: theme.spacing(2)
    },
  }));

  const classes = useStyles();

  const handleClose = (result?: boolean) => {
    openEditDialog(false);
    if (result){
      updateAuthorizations(tabId, [...authFieldValues.values()]);
      // notifications.success('Authorizations updated succesfully!');
    } else {
      // notifications.info('No changes made!');
    }
    clearFieldValues();
  };

  const handleDateChange = (date: Date | null, field: IField) => {
    const finalValue = moment(date).isValid() ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null;
    updateFieldValue(field, finalValue);
  };

  const handleDropdownOpen = (e: React.ChangeEvent<{}>, field: IField) => {
    openTimeZoneDropDown();
  }

  const handleDropdownClose = (e: React.ChangeEvent<{}>, field: IField) => {
    closeTimeZoneDropDown();
  }

  const handleDropdownSelect = (value: LibItem | null, field: IField) => {
    if (!value || value?.value === '') {
      value = null
    }
    updateFieldValue(field, value);
  }
  
  const buildDateTimeField = (field: IField) => {
    const idName = field.name.split(" ").join();
    const value = authFieldValues.get(field.id)?.dateValue;
    const dateValue = value ? moment(value).utc() : null;    

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          animateYearScrolling
          variant="inline"
          fullWidth
          format="MM/dd/yyyy HH:mm"
          margin="normal"
          id={`date-${idName}-type-${field.fieldType}`}
          label={field.name}
          value={dateValue}
          autoOk={true}
          onChange={(date: Date | null) => handleDateChange(date, field)}
        />
      </MuiPickersUtilsProvider>
    );
  }

  const getDefaultTimeZone = (field: IField) => {
    const lib = lookupValuesRegistry.get(field.id);

    if(!lib){
      return null;
    }
    
    const tabDefaultValues = lib.filter(y => y.isSelected === true && y.tabId === tabId);
    const defaultValue = tabDefaultValues[0] ?? lib.find(y => y.isSelected === true) ?? null;      
    return defaultValue;                                
  }

  const buildDropdown = (field: IField) => {    
    const idName = field.name.split(" ").join();
    const dropDownValue = getDefaultTimeZone(field);
    
    return (
      <React.Fragment>
        <Autocomplete
          id={`select-${idName}-type-${field.fieldType}`}
          options={[...loadLookups(field.id, tabId)]}
          getOptionLabel={(option) => option.name}
          open={isTimeZoneDropDownOpen}
          onClose={(e) => handleDropdownClose(e, field)}
          onOpen={(e) => handleDropdownOpen(e, field)}
          value={dropDownValue}
          onChange={(e, value) => handleDropdownSelect(value, field)}
          fullWidth
          className={classes.autocompleteContainer}
          renderOption={({ name, description }) => {
            return (
              <div>
                <Tooltip title={`${description ?? name}`} placement="right">
                  <div>
                      {name}
                  </div>
                </Tooltip>
              </div>
            );
          }}
          renderInput={(params) =>
            <TextField {...params} label={field.name} />
          }
        />
      </React.Fragment>
    )
  }


  const buildGridContent = (field: IField) => {

    let content;

    switch (field.fieldType) {
      case FieldType.Dropdown:
        content = buildDropdown(field);
        break;
      case FieldType.DateTime:
        content = buildDateTimeField(field);
        break;
      default:
        content = "";
        break;
    }

    return (content)
  }

  return (
    <Dialog open={isOpenedEditDialog} maxWidth="lg" fullWidth={true} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={{ marginBottom: -20 }}>
        Update Authorization Fields
      </DialogTitle>
      <Grid container justify="space-between" style={{ padding: "5px 24px 15px", marginBottom: 0 }}>
        <Grid item>
          <DialogContentText>
            Update fields for {tab?.name} <br />
            <small>{`${authFieldValues.size} of ${tabs.get(tabId)?.fields.length} changes`}</small>
          </DialogContentText>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => handleClose()}>
            Cancel
          </Button>
          &nbsp; &nbsp;
          <Button variant="outlined" color="primary" 
            disabled={authFieldValues.size === 0} onClick={() => handleClose(true)}>
            Save
          </Button>
        </Grid>
      </Grid>
      <DialogContent style={{ padding: "25px" }}>
        <Card variant="outlined" style={{ marginBottom: 10 }}>
          <CardContent style={{ padding: 40, paddingTop: 20 }}>
            <Grid container spacing={3}>
              {fields?.map((value, i) => {
                return(
                  <Grid item xs={4} key={`field-${value?.id}`}>
                    {buildGridContent(value)}
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

export default observer(AuthorizationForm)
