import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { IFieldGroup } from '../../app/models/fieldGroup';
import { FieldType, IField } from '../../app/models/field';
import { FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Tooltip } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
} from '@material-ui/pickers';
import { RootStoreContext } from '../../app/stores/rootStore';
import AudioTypePopover from './AudioTypePopover';
import { Autocomplete } from '@material-ui/lab';
import { LibItem } from '../../app/models/authorization';
import moment from 'moment';

interface IProps {
  tabId: number;
  group?: IFieldGroup;
  fields?: IField[];
}

const textfieldStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "1.3rem"
    }
  }
}));

const audiofieldStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "1.3rem"
    },
    cursor: "pointer"
  },
  pointer: {
    cursor: "pointer"
  }
}));

const datefieldStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 0
  }
}));

const MetaDataGroup: React.FC<IProps> = ({
  tabId,
  group,
  fields
}) => {
  const textFieldClasses = textfieldStyles();
  const audioFieldClasses = audiofieldStyles();
  const dateFieldClasses = datefieldStyles();
  const rootStore = useContext(RootStoreContext);

  const {
    updateFieldValue,
    selectedFieldValues,
    loadSelectedLookups,
    openedId,
    openDropdown,
    openAudioField,
    audioFieldTextValue,
    missingrequiredFields,
    loadLookups
  } = rootStore.metadatastore

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: IField) => {
    updateFieldValue(field, e.target.value.trim());
  }

  const handleDropdownSelect = (value: LibItem | null, field: IField) => {
    if (!value || value?.value === '') {
      value = null
    }
    updateFieldValue(field, value);
  }

  const handlePresetSelect = async (value: LibItem | null, field: IField) => {
    if (!value || value?.value === '') {
      value = null
    }

    const szJson: string = value?.value!;
    var json = JSON.parse(szJson);

    json.fields.forEach((el: any) => {
      var foundField = fields?.find(e => e.id == el.id)!;

      const option: LibItem = {
        name: el.value.name,
        value: el.value.value,
        description: '',
        tabId: el.tabId,
        isSelected: true
      };

      updateFieldValue(foundField, option);
    });

    updateFieldValue(field, value);
  }

  const handleMultipleSelect = (e: React.ChangeEvent<{ value: unknown }>, field: IField) => {
    const values = e.target.value as string[];
    let lookupValues = loadLookups(field.id, tabId);
    if (lookupValues) {
      const filtered = lookupValues.filter(x => values.includes(x.value))
      updateFieldValue(field, filtered);
    }
  }

  const handleDropdownOpen = (e: React.ChangeEvent<{}>, field: IField) => {
    openDropdown(field.id, true)
  }

  const handleDropdownClose = (e: React.ChangeEvent<{}>, field: IField) => {
    openDropdown(field.id);
  }

  const handleAudioFieldClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, field: IField) => {
    openAudioField(field.id, e.currentTarget);
  }

  const handleDateChange = (date: Date | null, field: IField) => {
    const finalValue = moment(date).isValid()? moment(date).format("YYYY-MM-DDTHH:mm:ss") : null;
    updateFieldValue(field, finalValue)
  };

  const checkMissingValue = (field: IField) => {
    return field.isRequired && missingrequiredFields.get(field.id) && !selectedFieldValues.get(field.id);
  }

  const buildTextField = (field: IField) => {
    const isValueMissing = checkMissingValue(field)
    return (
      <TextField
        error={isValueMissing}
        required={field.isRequired}
        helperText={isValueMissing ? "This field is required" : ""}
        fullWidth
        id={`input-${field.name.split(" ").join()}-type-${field.fieldType}`}
        label={field.name}
        // defaultValue={selectedFieldValues.get(field.id)?.textValue ?? ""}
        onChange={(e) => handleTextChange(e, field)}
        InputLabelProps={{
          shrink: true,
        }}
        className={textFieldClasses.root}
      />
    )
  }

  const buildPreset = (field: IField) => {
    const idName = field.name.split(" ").join();
    const isValueMissing = checkMissingValue(field)
    const dropDownValue = loadSelectedLookups(field, tabId);

    return (
      <React.Fragment>
        <Autocomplete
          id={`select-${idName}-type-${field.fieldType}`}
          options={[...loadLookups(field.id, tabId)]}
          getOptionLabel={(option) => option.name}
          open={openedId === field.id}
          onClose={(e) => handleDropdownClose(e, field)}
          onOpen={(e) => handleDropdownOpen(e, field)}
          value={dropDownValue}
          onChange={(e, value) => handlePresetSelect(value, field)}
          fullWidth
          renderOption={({ name, description }) => {
            return (
              <div>
                <Tooltip title={`${description ?? name}`} placement="right">
                  <div>
                      {name}
                  </div>
                </Tooltip>
              </div>
            );
          }}
          renderInput={(params) =>
            <TextField {...params} label={field.name}
              error={isValueMissing}
              required={field.isRequired}
              helperText={isValueMissing ? "This field is required" : ""}
            />
          }
        />
      </React.Fragment>
    )
  }

  const buildDropdown = (field: IField) => {
    const idName = field.name.split(" ").join();
    const isValueMissing = checkMissingValue(field)
    const dropDownValue = loadSelectedLookups(field, tabId);

    return (
      <React.Fragment>
        <Autocomplete
          id={`select-${idName}-type-${field.fieldType}`}
          options={[...loadLookups(field.id, tabId)]}
          getOptionLabel={(option) => option.name}
          open={openedId === field.id}
          onClose={(e) => handleDropdownClose(e, field)}
          onOpen={(e) => handleDropdownOpen(e, field)}
          value={dropDownValue}
          onChange={(e, value) => handleDropdownSelect(value, field)}
          fullWidth
          renderOption={({ name, description }) => {
            return (
              <div>
                <Tooltip title={`${description ?? name}`} placement="right">
                  <div>
                      {name}
                  </div>
                </Tooltip>
              </div>
            );
          }}
          renderInput={(params) =>
            <TextField {...params} label={field.name}
              error={isValueMissing}
              required={field.isRequired}
              helperText={isValueMissing ? "This field is required" : ""}
            />
          }
        />
      </React.Fragment>
    )
  }

  const buildMultiselect = (field: IField) => {
    const idName = field.name.split(" ").join();
    const isValueMissing = checkMissingValue(field)
    return (
      <React.Fragment>
        <FormControl required={field.isRequired} error={isValueMissing}>
          <InputLabel id={`label-${idName}`}>{field.name}</InputLabel>
          <Select
            // helperText="This field is required"
            labelId={`label-${idName}`}
            id={`select-${idName}-type-${field.fieldType}`}
            multiple={true}
            fullWidth
            value={(selectedFieldValues.get(field.id)?.multipleValues ?? []).map(x => x.value)}
            open={openedId === field.id}
            onClose={(e) => handleDropdownClose(e, field)}
            onOpen={(e) => handleDropdownOpen(e, field)}
            onChange={(e) => handleMultipleSelect(e, field)}
          >
            {[...loadLookups(field.id, tabId) ?? []].map((x, i) => {
              return <MenuItem key={`option${i}-${field.id}`} value={x.value}>{x.name}</MenuItem>
            })}
          </Select>
          {isValueMissing ? <FormHelperText>This field is required</FormHelperText> : ""}
        </FormControl>
      </React.Fragment>
    )
  }

  const buildAudioField = (field: IField) => {
    const isValueMissing = checkMissingValue(field)
    return (
      <React.Fragment>
        <TextField
          error={isValueMissing}
          helperText={isValueMissing ? "This field is required" : ""}
          required={field.isRequired}
          id={`input-${field.name.split(" ").join()}-type-${field.fieldType}`}
          label={field.name}
          fullWidth
          value={audioFieldTextValue}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            readOnly: true,
            className: audioFieldClasses.pointer
          }}
          onClick={(e) => handleAudioFieldClick(e, field)}
          className={audioFieldClasses.root}
        />
        <AudioTypePopover field={field} />
      </React.Fragment>
    )
  }

  const buildDateField = (field: IField) => {
    const idName = field.name.split(" ").join();
    const value = selectedFieldValues.get(field.id)?.dateValue;
    const dateValue = value ? moment(value).utc() : null;
    const isValueMissing = checkMissingValue(field);

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={isValueMissing}
          helperText={isValueMissing ? "This field is required" : ""}
          required={field.isRequired}
          animateYearScrolling
          variant="inline"
          fullWidth
          format="MM/dd/yyyy"
          margin="normal"
          id={`date-${idName}-type-${field.fieldType}`}
          label={field.name}
          value={dateValue}
          autoOk={true}
          onChange={(date: Date | null) => handleDateChange(date, field)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          className={dateFieldClasses.root}
        />
      </MuiPickersUtilsProvider>
    )
  }

  const buildDateTimeField = (field: IField) => {
    const idName = field.name.split(" ").join();
    const value = selectedFieldValues.get(field.id)?.dateValue;
    const dateValue = value ? moment(value).utc() : null;
    const isValueMissing = checkMissingValue(field);

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker 
          error={isValueMissing}
          helperText={isValueMissing ? "This field is required" : ""}
          required={field.isRequired}
          animateYearScrolling
          variant="inline"
          fullWidth
          format="MM/dd/yyyy HH:mm"
          margin="normal"
          id={`date-${idName}-type-${field.fieldType}`}
          label={field.name}
          value={dateValue}
          autoOk={true}
          onChange={(date: Date | null) => handleDateChange(date, field)}
        className={dateFieldClasses.root}   />
      </MuiPickersUtilsProvider>
    );
  }

  const buildGridContent = (field: IField) => {

    let content;

    switch (field.fieldType) {
      case FieldType.Textbox:
        content = buildTextField(field);
        break;
      case FieldType.Dropdown:
        content = buildDropdown(field);
        break;
      case FieldType.Preset:
        content = buildPreset(field);
        break;
      case FieldType.Multiselect:
        content = buildMultiselect(field);
        break;
      case FieldType.Audio:
        content = buildAudioField(field);
        break;
      case FieldType.Date:
          content = buildDateField(field);
          break;
      case FieldType.DateTime:
          content = buildDateTimeField(field);
          break;
      default:
        content = "";
        break;
    }

    return (content)
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {fields?.map((value, i) => {
          return (
            <Grid item xs={value.fieldType === FieldType.Audio ? 8 : 4} key={`g${group?.id}_column${i}`}>
              {buildGridContent(value)}
            </Grid>
          )
        })}
      </Grid>
    </React.Fragment>
  );
}

export default observer(MetaDataGroup);