import { Grid, TextField } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { RootStoreContext } from '../../app/stores/rootStore'

interface IProps {
	hierarchyId: number
}

const DeliverableIdFields: React.FC<IProps> = ({
	hierarchyId
}) => {

	const rootStore = useContext(RootStoreContext);
	const {
		devIdworkspace,
		deliverableIds,
		updateDeliverableIds,
		tabChange
	} = rootStore.workspaceStore;

	React.useEffect(() => {
		if (!devIdworkspace) {
			tabChange(true, hierarchyId);
		}
  },[devIdworkspace, tabChange])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let ids: string[] = [];
		const value = event.target.value as string;
		if (value.trim().length > 0) {
			ids = value.split(",").map(x => x.trim());
		}
		updateDeliverableIds(ids);
	}

	return (
		<React.Fragment>
			<Grid container spacing={2}>
				<Grid item xs={4} style={{ minHeight: "400px" }}>
					<TextField
						id="standard-multiline-static"
						label="Deliverable ID(s)"
						placeholder="Enter one or more deliverable ID's"
						multiline
						// variant="outlined"
						fullWidth
						rows={4}
						defaultValue={deliverableIds}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

export default observer(DeliverableIdFields)
