import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AuthorizationTab from './authorizationTab';
import { IAuthorizationTab, tabStatusColor, TabStatus } from '../../../app/models/authorizationTab';
import { IWorkspace } from '../../../app/models/workspace';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { CircularProgress, Grid } from '@material-ui/core';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

const tabStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "inherit !important",
    boxShadow: "none !important",
    borderBottom: "0.2px solid lightgray"
  },
  success: {
    color: "#1C826B"
  },
  warning: {
    color: "#F78965"
  },
  default: {
    color: "#000"
  },
  defaultTab: {
    minHeight: "50vh",
  },
  defaultIcon: {
    fontSize: "150px",
    opacity: 0.4,
    position: "relative",
  },
  defaultTabGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  defaultText: {
    opacity: 0.4,
    color: theme.palette.grey[500],
    margin: 0
  },
  dirtyTab: {
    backgroundColor: "lightgray",
    fontWeight: "bolder"
  },
  dirtyTabText: {
    fontSize: 18
  }
}));

interface IProps {
  workspace: IWorkspace | null,
}

const AuthorizationTabs: React.FC<IProps> = ({
  workspace,
}) => {
  const [value, setValue] = React.useState('0');

  const rootStore = useContext(RootStoreContext);

  const {
    tabs,
    tabStatuses,
    authorizationsRegistry
  } = rootStore.authorizationStore

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const classes = tabStyles();

  const getAuthCounts = (tab: IAuthorizationTab | undefined) => {
    if (!tab) 
      return (<CircularProgress color="inherit" size="14px" />);

    const textValue = `${tab.downloadedAuthorizationsCount}/${tab.authorizationsCount}`;
    
    if (tab.status.id === TabStatus.Fulfilled)
      return (<strong>({textValue})</strong>);
    else if (tab.status.id === TabStatus.NotFulfilled)
      return (<strong>({textValue})</strong>);
    else
      return null;
  }

  const buildTabs = () => {
    const tablist = [...tabs.values()].map((value, i) => {
      const dirtyRecords = authorizationsRegistry.get(value.id)?.changedAuthorizations.size;
      const tabStatus = tabStatuses.get(value.id);
      return <Tab key={`tab_${value.id}`} 
              label={
                <React.Fragment>
                  <div style={{ display: "block", color: tabStatusColor.checkAndGetStatusColor((tabStatus?.status.id) ?? 0) }}>
                    {value.name} {getAuthCounts(tabStatus)}  {dirtyRecords ? <strong className={classes.dirtyTabText}> *</strong> : ''}
                  </div>
                </React.Fragment>
              } 
              title={dirtyRecords ? `This tab has ${dirtyRecords} pending change(s).` : ''} 
              value={value.id.toString()} 
              className={dirtyRecords ? classes.dirtyTab : undefined}
             />
    });

    tablist.unshift(<Tab key={`tab_${0}`} style={{ display: "none" }} label="hidden" value="0" />);

    return tablist;
  }

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static" className={classes.appBar}>
          <TabList variant="scrollable" onChange={handleChange} aria-label="authorization tabs">
            {buildTabs()}
          </TabList>
        </AppBar>
        <TabPanel key={`tabPanel_${0}`} value="0">
          <Grid container justify="center" className={classes.defaultTab}>
            <Grid item className={classes.defaultTabGrid}>
              <FindInPageOutlinedIcon fontSize="large" color="disabled" className={classes.defaultIcon}/>
              <h3 className={classes.defaultText}>Select an asset group</h3>
              <h3 className={classes.defaultText}>above to view title versions</h3>
            </Grid>
          </Grid>
        </TabPanel>
        {[...tabs.values()].map((value, i) =>
            <TabPanel key={`tabPanel_${value.id}`} value={value.id.toString()}>
              <AuthorizationTab 
                key={`authTab_${value.id}`} 
                tab={value}
                workspace={workspace}
              />
            </TabPanel>)}
      </TabContext>
    </div>
  );
}

export default observer(AuthorizationTabs);