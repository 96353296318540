import { IField } from './field';
import { PriorityType } from './authorizationTab';

export interface IBaseFieldGroup {
  id: PriorityType;
  name: string;
}

export interface IFieldGroup extends IBaseFieldGroup {
  fields: IField[]
}

export class FieldGroup implements IFieldGroup {
  id: PriorityType;
  name: string;
  fields: IField[];

  constructor(id: PriorityType, name: string) {
    this.id = id;
    this.name = name;
    this.fields = [];
  }
}